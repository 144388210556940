import React, { FC, ReactNode } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { StyledIconContainer, StyledSectionTitle, StyledSectionTitleContainer } from './style';

interface ISectionTitle {
  title: ReactNode;
  subtitle?: ReactNode;
  icon?: ReactNode;
}

const SectionTitle: FC<ISectionTitle> = ({ title, subtitle, icon }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledSectionTitle sx={{ color: 'secondary.contrast' }}>
      <StyledSectionTitleContainer>
        {!isMobile && icon && <StyledIconContainer>{icon}</StyledIconContainer>}
        <StyledSectionTitle>
          {isMobile && icon && (
            <StyledIconContainer sx={{ mb: { xs: 4, sm: 2 } }}>{icon}</StyledIconContainer>
          )}
          <Typography sx={{ mb: { xs: 4, sm: 2 } }} variant={isMobile ? 'h6' : 'h3'}>
            {title}
          </Typography>
          {!isMobile && subtitle && (
            <Typography variant={'body1'} sx={{ color: 'text.secondary', mb: 4 }}>
              {subtitle}
            </Typography>
          )}
        </StyledSectionTitle>
        {isMobile && subtitle && (
          <Typography variant={'body2'} sx={{ color: 'text.secondary', mb: 4 }}>
            {subtitle}
          </Typography>
        )}
      </StyledSectionTitleContainer>
    </StyledSectionTitle>
  );
};

export default SectionTitle;
