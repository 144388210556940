import { add, addDays, isAfter, isSameDay } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleDisabledDates = (
  day: any,
  availableDates?: Array<Date>,
  availableNumberOfDaysToChooseFromToday?: number,
) => {
  if (availableNumberOfDaysToChooseFromToday) {
    const lastAvailableDayToPick = addDays(new Date(), availableNumberOfDaysToChooseFromToday - 1);
    return isAfter(day, lastAvailableDayToPick);
  }

  if (availableDates?.length) {
    return !availableDates?.some((d) => isSameDay(d, day));
  }

  return false;
};

export const checkIfDateIsInAllowedRange = (date: Date, range: number) => {
  const today = new Date();
  const allowedRange = add(today, { days: range });
  return date < allowedRange;
};
