import { Typography } from '@mui/material';
import React, { FC } from 'react';

import {
  StyledSummaryDataContainer,
  StyledSummaryDataRow,
} from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/style';

export interface SummaryItem {
  label?: string;
  value?: string | number;
}

interface ReservationSummaryContainer {
  summaryItems: Array<SummaryItem>;
}

const ReservationSummaryContainer: FC<ReservationSummaryContainer> = ({ summaryItems }) => (
  <StyledSummaryDataContainer>
    {summaryItems?.map((item, idx) => {
      if (item.label && item.value) {
        return (
          <StyledSummaryDataRow key={`${item.label}${item.value}${idx}`}>
            <Typography variant="body2" color={'text.secondary'}>
              {item.label}
            </Typography>
            <Typography variant="body2" color={'text.primary'}>
              {item.value}
            </Typography>
          </StyledSummaryDataRow>
        );
      }
    })}
  </StyledSummaryDataContainer>
);
export default ReservationSummaryContainer;
