import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledButtonsGroupContainer = styled(Box)(({ theme }) => ({
  marginLeft: '0px',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('sm')]: {
    marginLeft: '62px',
    marginBottom: '0px',
  },
}));

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    maxWidth: '920px',
  },
}));

export const StyledCalendarContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}));

export const StyledLoaderContainer = styled(Box)(({ theme }) => ({
  width: '750px',
  position: 'absolute',
  borderRadius: '8px',
  height: '340px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
