import * as React from 'react';
import { Alert, Snackbar } from '@mui/material';

import PageHeader from 'infrastructure/components/PageHeader';
import FadeInTransition from 'infrastructure/components/FadeInTransition';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectErrorStatus, selectShowError } from 'infrastructure/redux/slices/auth.selector';
import { setShowError } from 'infrastructure/redux/slices/auth.slice';
import { getMindBodyLogo } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

import { MainWrapper, PageWrapper, StyledContainer, StyledFooter } from './style';

interface PageLayout {
  hideSideNav?: boolean;
  children: React.ReactNode;
  pageTitle?: string;
  ctaButton?: React.ReactNode;
  shouldFadeIn?: boolean;
}

const PageLayout: React.FC<PageLayout> = ({ children, pageTitle, shouldFadeIn }) => {
  const snackbarOpen = useAppSelector(selectShowError);
  const errorStatus = useAppSelector(selectErrorStatus);
  const dispatch = useAppDispatch();
  const Logo = getMindBodyLogo();
  return (
    <PageWrapper>
      <Snackbar
        open={snackbarOpen && errorStatus !== 404}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowError(false));
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">
          {errorStatus === 401
            ? "We're sorry, but the sign-in link you clicked is invalid or expired. Please confirm the correct link was used or navigate back to the sign-in page and try again."
            : 'Sorry we’ve encountered an error, please try again later.'}
        </Alert>
      </Snackbar>
      <PageHeader pageTitle={pageTitle} />
      <MainWrapper component="main">
        {shouldFadeIn ? (
          <FadeInTransition shouldStartTransition={true}>
            <StyledContainer maxWidth="lg">{children}</StyledContainer>
          </FadeInTransition>
        ) : (
          <StyledContainer maxWidth="lg">{children}</StyledContainer>
        )}
      </MainWrapper>
      <StyledFooter>
        <Logo />
      </StyledFooter>
    </PageWrapper>
  );
};

export default PageLayout;
