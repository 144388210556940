import { ContainerModule } from 'inversify';

import IWebModule, { WebModule } from 'infrastructure/targets/web/types/IWebModule';
import { BookingWizardNavigationHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import { ExternalNavigationHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';

import BookingWizardModule from './module';
import useBookingWizardNavigation from './hooks/useBookingWizardNavigation';
import useExternalNavigation from './hooks/useExternalNavigation';

const bookingWizardContainerModule = new ContainerModule((bind) => {
  bind<IWebModule>(WebModule).to(BookingWizardModule);
  bind(BookingWizardNavigationHook).toFunction(useBookingWizardNavigation);
  bind(ExternalNavigationHook).toFunction(useExternalNavigation);
});

export default bookingWizardContainerModule;
