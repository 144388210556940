import { Checkbox, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { FC, useEffect, useState } from 'react';

import { InstructorType } from 'domain/entities/Instructor';

import {
  StyledBorderlessSelectInput,
  StyledFormControl,
  StyledMenuItemWithTransparentBg,
} from './style';

interface IInstructorsFilterSelect {
  onChange?: (value: string[]) => void;
}

const InstructorsFilterSelect: FC<IInstructorsFilterSelect> = ({ onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const instructorTypes = Object.keys(InstructorType).map((value) => ({
    name: InstructorType[value as keyof typeof InstructorType],
    value,
  }));
  const [selectedInstructorTypes, setSelectedInstructorTypes] = useState<Array<string>>(
    Object.values(InstructorType),
  );

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedInstructorTypes(event.target.value as string[]);
  };

  const renderFilterValue = (selected: string[]) => {
    if (isMobile) {
      return 'Filter Instructors';
    }
    if (selected.length === Object.keys(InstructorType)?.length) {
      return 'All Instructors';
    }
    return selected.map((value) => value).join(', ');
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedInstructorTypes);
    }
  }, [selectedInstructorTypes]);

  return (
    <StyledFormControl>
      <Select
        multiple
        value={selectedInstructorTypes}
        onChange={handleChange}
        input={<StyledBorderlessSelectInput />}
        renderValue={renderFilterValue}>
        {instructorTypes?.map((instructorType) => (
          <StyledMenuItemWithTransparentBg key={instructorType.value} value={instructorType.name}>
            <Checkbox
              color="secondary"
              checked={
                selectedInstructorTypes?.findIndex((item) => item === instructorType.name) >= 0
              }
            />
            <ListItemText primary={instructorType.name} />
          </StyledMenuItemWithTransparentBg>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default InstructorsFilterSelect;
