import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'infrastructure/targets/web/theme/consts';
import { getTopBannerPictureUrl } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: HEADER_HEIGHT_MOBILE,
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 99.99%, rgba(0, 0, 0, 0) 100%),
    url(${getTopBannerPictureUrl()})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: 'colors.white',
  padding: theme.spacing(5, 4, 5, 4),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 11, 5, 11),
    minHeight: HEADER_HEIGHT_DESKTOP,
  },
}));

export const StyledTitleContainer = styled(Box)(() => ({
  display: 'flex',
  flex: '0.5 1 auto',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledHeaderTitle = styled(Typography)(() => ({
  textAlign: 'center',
  paddingTop: '10px',
}));
