import { createSlice } from '@reduxjs/toolkit';

export enum PromoCodeType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export type PromoCodeData = {
  code: string;
  codeType: PromoCodeType;
  amount: number;
  isDaily: boolean;
};

export interface PaymentForm {
  nameOnCard?: string;
  cardNumber?: string;
  expirationDate?: string;
  ccv?: string;
  address?: string;
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
  promoCodeData?: PromoCodeData;
  smallEventFee?: number;
  total?: number;
  removeMemberDiscount?: boolean;
  payByPoints?: boolean;
  pointsPrice?: number;
  pointsSmallFeePrice?: number;
  pointsToEarn?: number;
}

const paymentFormSlice = createSlice({
  name: 'paymentForm',
  initialState: {},
  reducers: {
    setNameOnCard: (state: PaymentForm, { payload }) => ({
      ...state,
      nameOnCard: payload.nameOnCard,
    }),
    setCardNumber: (state: PaymentForm, { payload }) => ({
      ...state,
      cardNumber: payload.cardNumber,
    }),
    setExpirationDate: (state: PaymentForm, { payload }) => ({
      ...state,
      expirationDate: payload.expirationDate,
    }),
    setCCV: (state: PaymentForm, { payload }) => ({
      ...state,
      ccv: payload.ccv,
    }),
    setAddress: (state: PaymentForm, { payload }) => ({
      ...state,
      address: payload.address,
    }),
    setCity: (state: PaymentForm, { payload }) => ({
      ...state,
      city: payload.city,
    }),
    setCountry: (state: PaymentForm, { payload }) => ({
      ...state,
      city: payload.city,
    }),
    setState: (state: PaymentForm, { payload }) => ({
      ...state,
      state: payload.state,
    }),
    setZip: (state: PaymentForm, { payload }) => ({
      ...state,
      zip: payload.zip,
    }),
    setPromoCodeData: (state: PaymentForm, { payload }) => ({
      ...state,
      promoCodeData: payload.promoCodeData,
    }),
    setSmallEventFee: (state: PaymentForm, { payload }) => ({
      ...state,
      smallEventFee: payload.smallEventFee,
    }),
    setTotalAmountToPay: (state: PaymentForm, { payload }) => ({
      ...state,
      total: payload.total,
    }),
    setRemoveMemberDiscount: (state: PaymentForm, { payload }) => ({
      ...state,
      removeMemberDiscount: payload.removeMemberDiscount,
    }),
    setPointsPrice: (state: PaymentForm, { payload }) => ({
      ...state,
      pointsPrice: payload.pointsPrice,
    }),
    setPointsSmallFeePrice: (state: PaymentForm, { payload }) => ({
      ...state,
      pointsSmallFeePrice: payload.pointsSmallFeePrice,
    }),
    setPointsToEarn: (state: PaymentForm, { payload }) => ({
      ...state,
      pointsToEarn: payload.pointsToEarn,
    }),
    setPayByPoints: (state: PaymentForm, { payload }) => ({
      ...state,
      payByPoints: payload.payByPoints,
    }),
    clearPayByPoints: (state: PaymentForm) => ({
      ...state,
      payByPoints: undefined,
    }),
    clearPaymentData: () => ({
      undefined,
    }),
  },
});

export const {
  setNameOnCard,
  setCardNumber,
  setExpirationDate,
  setCCV,
  setAddress,
  setCity,
  setCountry,
  setState,
  setZip,
  setPromoCodeData,
  setSmallEventFee,
  setTotalAmountToPay,
  setRemoveMemberDiscount,
  clearPaymentData,
  setPointsPrice,
  setPointsSmallFeePrice,
  clearPayByPoints,
  setPointsToEarn,
  setPayByPoints,
} = paymentFormSlice.actions;
export default paymentFormSlice.reducer;
