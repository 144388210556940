import { useInjection } from 'inversify-react';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';

export const GetLessonAvailabilityAdapter = Symbol('GetLessonAvailabilityAdapter');
export type IGetLessonAvailabilityAdapter = InjectableHook<
  AsyncHookResult<Array<Date>, unknown> & {
    fetchFurtherAvailableDates: (newDate: Date | null) => void;
  }
>;

const useCaseSelectLessonReservationDate = () => {
  const adapter = useInjection<IGetLessonAvailabilityAdapter>(GetLessonAvailabilityAdapter);
  const { error, result, inProgress, fetchFurtherAvailableDates } = adapter();

  return {
    fetchFurtherAvailableDates,
    inProgress,
    error,
    result,
  };
};

export default useCaseSelectLessonReservationDate;
