import { PaletteOptions } from '@mui/material/styles/createPalette';

export default {
  text: {
    primary: '#222222',
    secondary: '#555555',
  },
  primary: {
    main: '#F50057',
    dark: '#AB003C',
    light: '#F73378',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#14CEC6',
    dark: '#0E908A',
    light: '#43D7D1',
    contrastText: '#FFFFFF',
    states: {
      selected: '#14CEC614',
    },
  },
  error: {
    main: '#B20707',
    dark: '#7C0404',
    light: '#C13838',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#0288D1',
    dark: '#01579B',
    light: '#03A9F4',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#0C8145',
    dark: '#085A30',
    light: '#3C9A6A',
    contrastText: '#FFFFFF',
  },
  action: {
    active: '#0000008A',
    hover: '#0000000A',
    selected: '#00000014',
    focus: '#00000033',
    disabled: '#00000042',
    disabledBackground: '#0000001F',
  },
  other: {
    outlineBorder: '#0000003B',
    divider: '#0000001F',
    overlay: '#00000080',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
    neutral: '#555555',
  },
} as PaletteOptions;
