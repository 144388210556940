import {
  IPreOrderFoodHook,
  PreOrderFoodFormValues,
} from 'application/modules/bookingWizard/useCases/hooks/useCasePreOrderFood';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  selectConfirmedBooking,
  selectLocation,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';

const useSubmitPreOrderFood: IPreOrderFoodHook = () => {
  const locationId = useAppSelector(selectLocation);
  const confirmedBooking = useAppSelector(selectConfirmedBooking);
  const appointmentId = confirmedBooking?.[0]?.id;

  const [savePreOrderFoodData, { isLoading, isFetching, error, data, isSuccess }] =
    mboAPI.useSavePreOrderFoodDataMutation();

  const handleSavePreOrderFoodData = async (values: PreOrderFoodFormValues) => {
    if (!locationId || !appointmentId) {
      console.warn('Either location or appointment id is not provided');
      return;
    }
    await savePreOrderFoodData({
      locationId: locationId,
      body: {
        appointmentId: [appointmentId],
        order: Object.values(values.order)
          .filter((order) => typeof order !== 'undefined')
          .map((order) => ({
            id: order.productId,
            variation: order.variantId,
            name: order.name,
            quantity: order.quantity,
            price: order.price,
          })),
        notes: values.notes,
      },
    });
  };

  return {
    inProgress: isLoading || isFetching,
    error: error,
    result: data,
    isSuccess,
    handleSavePreOrderFoodData,
  };
};

export default useSubmitPreOrderFood;
