import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { StaticDatePicker } from '@mui/x-date-pickers';

export const StyledCalendarContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '90px',
  position: 'relative',
}));

export const StyledLeftDatePicker = styled(StaticDatePicker)(({ theme }) => ({
  '& .MuiPickersCalendarHeader-labelContainer': {
    order: 2,
    marginRight: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-31px',
    [theme.breakpoints.down('sm')]: {
      order: 3,
      justifyContent: 'flex-start',
      marginLeft: '15px',
    },
  },
  '& .MuiPickersArrowSwitcher-root': {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },

  '& .MuiDialogActions-root': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const StyledRighDatePicker = styled(StaticDatePicker)(() => ({
  '& .MuiPickersCalendarHeader-labelContainer': {
    order: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '-31px',
  },
  '& .MuiPickersArrowSwitcher-root': {
    order: 2,
    marginLeft: 'auto',
  },
}));
