import { Box, ToggleButton, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledButtonsGroupContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '100%',
  minWidth: '115px',
  height: '100%',
  display: 'block',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  paddingTop: '6px',
  paddingBottom: '6px',
  color: `${theme.palette.text.primary}`,
  '&.Mui-selected': {
    display: 'block',
    color: `${theme.palette.secondary.contrastText} !important`,
    '& .MuiTypography-body2': {
      color: `${theme.palette.secondary.contrastText} !important`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: 'auto',
  },
}));

export const StyledButtonSubtitle = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.text.secondary}`,
  [theme.breakpoints.down('sm')]: {
    paddingTop: '0px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0),
  },
}));

export const StyledToggleButtonContent = styled('span')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
