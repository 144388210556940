import { useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';

import useConfirmReservation from 'infrastructure/redux/adapters/bookingWizard/ConfirmReservationAdapter';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import { selectLocationDetails } from 'infrastructure/redux/slices/bookingWizard.selector';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import EmailLogoutSelect from 'infrastructure/targets/web/modules/bookingWizard/Steps/ConfirmReservationStep/EmailLogoutSelect';

import HeaderTopBar from './components/HeaderTopBar';
import { StyledHeader, StyledHeaderTitle, StyledTitleContainer } from './style';

interface IPageHeader {
  pageTitle?: string;
}

const PageHeader: FC<IPageHeader> = ({ pageTitle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedLocationDetails = useAppSelector(selectLocationDetails);
  const user = useAppSelector(selectUserSummary);
  const accessToken = useAppSelector(selectAccessToken);
  const phoneNumber = selectedLocationDetails?.telephone;
  const { handleLogout, handleMyBookingsRedirect } = useConfirmReservation();
  return (
    <StyledHeader component="header">
      <HeaderTopBar phoneNumber={phoneNumber} />
      <StyledTitleContainer>
        <StyledHeaderTitle
          variant={isMobile ? 'h3' : 'h2'}
          sx={{
            color: 'secondary.contrastText',
          }}>
          {pageTitle}
        </StyledHeaderTitle>
      </StyledTitleContainer>
      {user?.email && accessToken ? (
        <EmailLogoutSelect
          email={user?.email}
          role={user?.role}
          onLogout={handleLogout}
          handleMyBookingsRedirect={handleMyBookingsRedirect}
        />
      ) : null}
    </StyledHeader>
  );
};

export default PageHeader;
