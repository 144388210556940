import { createSelector } from '@reduxjs/toolkit';

import { Country } from 'domain/entities/Country';
import { RootState } from 'infrastructure/redux/store';

import { AuthState, ProviderName, ProviderUserInfo } from './auth.slice';

export const selectSelf = (storeState: RootState): RootState => storeState;

export const selectAuthState = createSelector(
  selectSelf,
  (state: RootState): AuthState => state.auth,
);

export const selectToken = createSelector(
  selectAuthState,
  (state: RootState): AuthState => state.token,
);

export const selectAccessToken = createSelector(
  selectAuthState,
  (state: RootState): string | null => state.accessToken,
);

export const selectRefreshToken = createSelector(
  selectAuthState,
  (state: RootState): string | null => state.refreshToken,
);

export const selectErrorStatus = createSelector(
  selectAuthState,
  (state: RootState): number | null => state.errorStatus,
);

export const selectShowError = createSelector(
  selectAuthState,
  (state: RootState): boolean => state.showError,
);

export const selectCountry = createSelector(
  selectAuthState,
  (state: RootState): Country => state.selectedCountry,
);

export const selectAPIbaseURL = createSelector(
  selectAuthState,
  (state: RootState): Country => state.apiBaseURL,
);

export const selectProviderName = createSelector(
  selectAuthState,
  (state: RootState): ProviderName => state.providerName,
);

export const selectProviderUserInfo = createSelector(
  selectAuthState,
  (state: RootState): ProviderUserInfo => state.providerUserInfo,
);
