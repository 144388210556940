import { useInjection } from 'inversify-react';
import { LocationTypes } from 'common';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';

export type IGetSimulatorLocationsHook = InjectableHook<
  AsyncHookResult<Array<LocationTypes.SimulatorLocation>, unknown>
>;

export const GetSimulatorLocationsAdapter = Symbol('GetSimulatorLocationsAdapter');

export type IGetSimulatorLocationsAdapter = InjectableHook<
  AsyncHookResult<Array<LocationTypes.SimulatorLocation>, unknown> & {
    fetchLocations: () => void;
    handleGetUserData: (newLocationID: string) => void;
  }
>;

const useCaseGetSimulatorLocations = () => {
  const adapter = useInjection<IGetSimulatorLocationsAdapter>(GetSimulatorLocationsAdapter);
  const { error, result, inProgress, fetchLocations, handleGetUserData } = adapter();

  const refetchUserDataWhenLocationIdChanged = (newLocationID: string) => {
    handleGetUserData(newLocationID);
  };

  return {
    inProgress,
    error,
    result,
    fetchLocations,
    refetchUserDataWhenLocationIdChanged,
  };
};

export default useCaseGetSimulatorLocations;
