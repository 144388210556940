import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

import LogoComponent from 'infrastructure/components/PageHeader/components/LogoComponent';
import { formatPhoneNumber } from 'infrastructure/targets/web/modules/common/helpers';

import { StyledPhoneNumber, StyledTopBar } from './style';

interface HeaderTopBar {
  phoneNumber?: string;
}

const HeaderTopBar: FC<HeaderTopBar> = ({ phoneNumber }) => (
  <StyledTopBar sx={{ color: 'secondary.contrastText' }}>
    <LogoComponent />
    <StyledPhoneNumber>
      <Box sx={{ mt: '2px' }}>
        {formatPhoneNumber(phoneNumber) && <LocalPhoneOutlinedIcon style={{ fill: 'white' }} />}
      </Box>
      <Typography variant="h6" sx={{ pl: 2, color: 'secondary.contrastText' }}>
        {formatPhoneNumber(phoneNumber)}
      </Typography>
    </StyledPhoneNumber>
  </StyledTopBar>
);

export default HeaderTopBar;
