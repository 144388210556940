import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTopBar = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flex: '0 1 auto',
  alignItems: 'center',
  maxHeight: '40px',
}));

export const StyledPhoneNumber = styled(Box)(() => ({
  display: 'flex',
  marginLeft: 'auto',
  flexDirection: 'row',
}));
