import React from 'react';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import {
  StyledBottomContainer,
  StyledContentContainer,
  StyledHorizontalCardContainer,
  StyledInnerRightCardContainer,
  StyledTitleContainer,
} from './style';

const HorizontalCardSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const imageSize = isMobile
    ? { width: '40px', height: '40px' }
    : { width: '180px', height: '180px' };

  return (
    <StyledHorizontalCardContainer>
      {!isMobile ? (
        <Box>
          <Skeleton variant="rounded" animation="wave" {...imageSize} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', gap: 4, mb: 4 }}>
          <Skeleton variant="rounded" animation="wave" {...imageSize} />
          <StyledTitleContainer sx={{ width: '100%' }}>
            <Skeleton variant="text" animation="wave" sx={{ width: '25%' }} />
          </StyledTitleContainer>
        </Box>
      )}

      <StyledInnerRightCardContainer sx={{ width: '100%' }}>
        {!isMobile && (
          <StyledTitleContainer>
            <Skeleton variant="text" animation="wave" sx={{ width: '25%' }} />
          </StyledTitleContainer>
        )}
        <StyledContentContainer sx={{ width: '100%' }}>
          <Skeleton variant="text" animation="wave" sx={{ width: '100%' }} />
          <Skeleton variant="text" animation="wave" sx={{ width: '100%' }} />
          <Skeleton variant="text" animation="wave" sx={{ width: '80%' }} />
        </StyledContentContainer>

        <StyledBottomContainer>
          <Box
            sx={{
              marginRight: { xs: 0, sm: 6 },
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              width: '100%',
              flex: '1 1 auto',
            }}>
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ minWidth: '20px', height: '48px', flex: '1 1 auto' }}
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ minWidth: '20px', height: '48px', flex: '1 1 auto' }}
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ minWidth: '20px', height: '48px', flex: '1 1 auto' }}
            />
          </Box>
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{
              marginLeft: 'auto',
              marginTop: { xs: 3, sm: 0 },
              minWidth: { xs: '100%', sm: '135px' },
              minHeight: '48px',
            }}
          />
        </StyledBottomContainer>
      </StyledInnerRightCardContainer>
    </StyledHorizontalCardContainer>
  );
};
export default HorizontalCardSkeleton;
