import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledRateCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  justifyContent: 'space-between',
  borderRadius: '0px 0px 8px 8px',
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.other.divider}`,
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledPriceBox = styled(Box)(({ theme }) => ({
  justifyContent: 'right',
  alignItems: 'right',
  color: theme.palette.primary.text,
  padding: theme.spacing(1, 1, 0, 1),
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'left',
  alignItems: 'left',
  color: theme.palette.primary.text,
  padding: theme.spacing(1, 1, 0, 1),
  [theme.breakpoints.down('sm')]: {},
}));
