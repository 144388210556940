import { GtmEventData } from 'common/dist/infrastructure/modules/event/interfaces/GtmEventTypes';

import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';

export const useGtmEvent = () => {
  const [sendGtmEventQuery, { isLoading, data, error }] = mboAPI.useLazySendGtmEventQuery();

  const sendGtmEvent = async (eventData: GtmEventData) => sendGtmEventQuery(eventData);

  return { isLoading, data, error, sendGtmEvent };
};
