import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const StyledFormContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
 // gap: '48px',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const StyledFoodContainer = styled(Box)(({ theme }) => ({
  width: '60vw',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));
