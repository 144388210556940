import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { setErrorStatus, setShowError } from 'infrastructure/redux/slices/auth.slice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stringifyRecursively = (_key: string, value: any) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value, stringifyRecursively);
  }
  return value;
};

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const errorStatusCode = action?.payload?.status;
  next(action); // This add because requests were stuck on pending state on error without it
  if (isRejectedWithValue(action)) {
    const isStatusCodeToLog = errorStatusCode > 399;

    if (isStatusCodeToLog) {
      const { method, url } = action?.meta?.baseQueryMeta?.request;
      const requestBody = JSON.stringify(
        action?.meta?.arg?.originalArgs?.body,
        stringifyRecursively,
      );
      const responseBody = action?.payload?.data;

      api.dispatch(setErrorStatus(errorStatusCode));
      api.dispatch(setShowError(true));

      Sentry.withScope((scope) => {
        scope.setExtra('Request method', method);
        scope.setExtra('Request URL', url);
        scope.setExtra('Request body', requestBody);
        scope.setExtra('Response code', errorStatusCode);
        scope.setExtra('Response body', responseBody);

        Sentry.captureException(new Error(JSON.stringify(action?.payload?.data?.message)));
      });
    }
  }

  return next(action);
};
