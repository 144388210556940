import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.other.outlineBorder}`,
  borderRadius: '8px',
  padding: theme.spacing(4),
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledSummaryDataContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  width: '100%',
}));

export const StyledSummaryDataRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(2),
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledSummaryDivider = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '1px',
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));
