import { Box, useTheme, Typography } from '@mui/material';
import React, { FC } from 'react';
import { StoreTypes } from 'common';

import { ReactComponent as GlutenFree } from 'infrastructure/targets/web/assets/icons/gluten-free.svg';
import { ReactComponent as Vegan } from 'infrastructure/targets/web/assets/icons//vegan.svg';
import { ReactComponent as Vegetarian } from 'infrastructure/targets/web/assets/icons//vegetarian.svg';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

interface IFoodAttribute {
  type: StoreTypes.AttributeTypes;
}

const FoodAttribute: FC<IFoodAttribute> = ({ type }) => {
  const t = useTranslationPrefix('pre_order_food.card');
  const theme = useTheme();
  const svgProps = { width: 20, height: 20, fill: theme.palette.secondary.main };
  const FoodAttributeMapping = {
    [StoreTypes.AttributeTypes.GF]: {
      label: t('gluten_free'),
      icon: <GlutenFree {...svgProps} />,
    },
    [StoreTypes.AttributeTypes.V]: {
      label: t('vegetarian'),
      icon: <Vegetarian {...svgProps} />,
    },
    [StoreTypes.AttributeTypes.VE]: {
      label: t('vegan'),
      icon: <Vegan {...svgProps} />,
    },
  };

  const FoodAttribute = FoodAttributeMapping[type];

  return (
    <Box sx={{ height: '20px', display: 'flex', flexDirection: 'row' }}>
      <Typography>{FoodAttribute.icon}</Typography>
      <Typography variant="subtitle2">{FoodAttribute.label}</Typography>
    </Box>
  );
};

export default FoodAttribute;
