import { useEffect } from 'react';

const useCaptureEmailFromURL = () => {
  useEffect(() => {
    // Get the current URL parameters
    const searchParams = new URLSearchParams(window.location.search);

    // Extract the email parameter
    const email = searchParams.get('email');

    // If email is present, save it in localStorage
    if (email && email !== "undefined") {
      localStorage.setItem('email', email);
    }
  }, []);
};

export default useCaptureEmailFromURL;
