import React, { useCallback, useEffect, useId, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, Stack } from '@mui/material';
import { RemoveOutlined as DecrementIcon, Add as IncrementIcon } from '@mui/icons-material';

import { StyledTextInput, StyledButton } from './style';

export interface NumberCounterProps {
  label?: string;
  value?: number;
  onChange?: (val: number) => void;
  min?: number;
  max?: number;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  sx?: Record<string, unknown>;
}

const isValueWithinMinMax = (v: number, min?: number, max?: number) => {
  if (typeof min !== 'undefined' && v < min) {
    return false;
  }

  return !(typeof max !== 'undefined' && v > max);
};

const NumberCounter = ({
  label,
  value,
  onChange,
  min,
  max,
  sx,
  helperText,
  error,
  disabled,
  required,
}: NumberCounterProps) => {
  const [count, setCount] = useState(value || min || 0);

  const setCountField = useCallback(
    (v: number) => {
      setCount(v);

      if (typeof onChange !== 'undefined') {
        onChange(v);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (typeof value !== 'undefined' && value !== count) {
      setCount(value);
    }
  }, [value, count]);

  useEffect(() => {
    if (typeof min !== 'undefined' && count < min) {
      setCountField(min);
    }

    if (typeof max !== 'undefined' && count > max) {
      setCountField(max);
    }
  }, [count, max, min, setCountField]);

  const id = useId();
  const helperTextId = helperText ? `${id}-helper-text` : undefined;

  return (
    <Stack gap={2} sx={sx}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <FormControl required={required} disabled={disabled} error={error}>
        <Stack direction="row" spacing={2} alignItems="center">
          <StyledButton
            variant="outlined"
            size="small"
            disabled={disabled || min === count}
            onClick={() => setCountField(count - 1)}>
            <DecrementIcon />
          </StyledButton>
          <StyledTextInput
            value={count}
            id={id}
            aria-describedby={helperTextId}
            variant="outlined"
            disabled={disabled}
            size={'small'}
            onChange={(e) => {
              const value = +e.target.value;
              Number.isInteger(value) && isValueWithinMinMax(value, min, max)
                ? setCountField(value)
                : null;
            }}
            inputProps={{ inputMode: 'numeric' }}
          />
          <StyledButton
            variant="outlined"
            size="small"
            disabled={disabled || max === count}
            onClick={() => setCountField(count + 1)}>
            <IncrementIcon />
          </StyledButton>
        </Stack>
        {helperText && (
          <FormHelperText sx={{ marginInline: 0 }} id={helperTextId}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};

export default NumberCounter;
