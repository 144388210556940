import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledValueContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTextContainer = styled(Box)(() => ({
  display: 'block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '90%',
  alignItems: 'center',
}));
