import { Box, Container } from '@mui/material';
import { styled } from '@mui/system';

export const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 4, 6, 4),
  boxSizing: 'border-box',
  flex: 1,
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 11, 6, 11),
  },
}));

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  minHeight: '100vh',
  width: '100%',
  flexDirection: 'column',
  '.nextButton': {
    display: 'flex !important',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      transform: 'none !important',
      position: 'fixed',
      bottom: theme.spacing(10),
      left: theme.spacing(4),
      width: `calc(100% - ${theme.spacing(6)})`,
    },
  },
  '.belowNextButton': {
    [theme.breakpoints.down('sm')]: {
      transform: 'none !important',
      position: 'fixed',
      bottom: theme.spacing(9),
      left: theme.spacing(4),
      display: 'block',
      width: `calc(100% - ${theme.spacing(6)})`,
    },
  },
}));

export const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  flex: 1,
  alignItems: 'center',
}));

export const StyledFooter = styled(Container)(({ theme }) => ({
  display: 'flex',
  marginTop: 'auto',
  maxHeight: '60px',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));
