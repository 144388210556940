import { Select } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSelect = styled(Select)(() => ({
  '& .MuiOutlinedInput-input': {
    paddingTop: '7px',
    paddingBottom: '7px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(0, 0, 0, 0.23) !important;',
  },
}));
