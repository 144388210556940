import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledPricingSummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledRateCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3),
  width: '100%',
  justifyContent: 'space-between',
  borderRadius: '0px 0px 8px 8px',
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.other.outlineBorder}`,
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledRateList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledRateDay = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'inline-block',
  width: '30%',
}));

export const StyledRateHour = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'inline-block',
  float: 'right',
  position: 'relative',
}));

export const StyledPriceBox = styled(Box)(({ theme }) => ({
  justifyContent: 'right',
  alignItems: 'right',
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 1, 0, 1),
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  alignItems: 'left',
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 1, 0, 1),
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledHoursList = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0),
  width: '100%',
}));
