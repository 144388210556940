import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { useState } from 'react';

import useCaseNavigateBookingWizard from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import {
  Guest,
  IReservationCompleteAdapter,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseReservationComplete';
import { WizardStep } from 'domain/entities/WizardStep';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import { clearAuthData, setShowError } from 'infrastructure/redux/slices/auth.slice';
import {
  selectConfirmedBooking,
  selectExperienceType,
  selectLocation,
  selectLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  clearBookingData,
  setAddons,
  setAppointments,
  setConfirmedBooking,
  setResourceId,
  setStartTimeAndSessionLength,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { clearPaymentData, setTotalAmountToPay } from 'infrastructure/redux/slices/payment.slice';
import { clearUserData } from 'infrastructure/redux/slices/user.slice';
import { getReduxStoreVersion } from 'infrastructure/redux/store';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';

const useReservationComplete: IReservationCompleteAdapter = () => {
  const dispatch = useAppDispatch();
  const { moveToStep } = useCaseNavigateBookingWizard(WizardStep.seeYouSoon);
  const { navigateToSignInPage } = useCaseExternalNavigation();
  const [sendWaiverLinks, { isLoading, isSuccess, isError }] =
    mboAPI.useSendWaiverLinksToGuestsMutation();
  const experienceType = useAppSelector(selectExperienceType);
  const [guestMap, setGuestMap] = useState<Guest[]>([{ name: 'guest1', email: '' }]);
  const accessToken = useAppSelector(selectAccessToken);
  const locationId = useAppSelector(selectLocation);
  const selectedLocation = useAppSelector(selectLocationDetails);
  const confirmedBooking = useAppSelector(selectConfirmedBooking);

  const handleLogout = () => {
    dispatch(clearUserData());
    dispatch(clearAuthData());
    dispatch(clearPaymentData());
    dispatch(clearBookingData());
    localStorage.removeItem(`persist:${getReduxStoreVersion()}`);
    navigateToSignInPage();
  };

  const resetBookingData = () => {
    dispatch(setAppointments({ appointments: undefined }));
    dispatch(setResourceId({ resourceId: undefined }));
    dispatch(setConfirmedBooking(undefined));
    dispatch(
      setStartTimeAndSessionLength({
        startTime: undefined,
        endTime: undefined,
        sessionLength: undefined,
      }),
    );
    dispatch(setTotalAmountToPay({ total: undefined }));
    dispatch(setAddons(undefined));
    dispatch(clearPaymentData());
  };

  const handleMakeAnotherBooking = () => {
    resetBookingData();
    switch (experienceType) {
      case BookingWizardFlowTypes.Simulator:
      case BookingWizardFlowTypes.Bowling:
        moveToStep(WizardStep.sessionLength);
        break;

      case BookingWizardFlowTypes.Lesson:
      case BookingWizardFlowTypes.FirstLesson:
        moveToStep(WizardStep.selectInstructor);
        break;
      default:
        break;
    }
  };

  const handleSendWaiverLinks = (emails: Record<string, string>) => {
    const waiverUrl = handleGetLocationUrl();
    const emailList = Object.values(emails);
    const firstBooking = confirmedBooking?.[0];
    const startDateTime = firstBooking?.startDateTime;
    let endDateTime = firstBooking?.endDateTime;
    if (confirmedBooking && confirmedBooking.length > 1) {
      const lastBooking = confirmedBooking[confirmedBooking.length - 1];
      endDateTime = lastBooking?.endDateTime;
    }

    if (locationId && firstBooking) {
      const body = {
        startDateTime,
        endDateTime,
        siteId: selectedLocation?.siteId,
        locationId: locationId,
        appointmentId: firstBooking?.id,
        waiverUrl: waiverUrl || '',
        clientId: firstBooking?.clientId,
        emails: emailList,
      };
      sendWaiverLinks({ locationId: locationId, body });
    }
  };

  const handleAddNextGuest = () => {
    const newGuest = { name: `guest${guestMap.length + 1}`, email: '' };
    setGuestMap([...guestMap, newGuest]);
  };

  const handleRemoveGuest = (guestToRemove: string) => {
    const newMap = guestMap.filter((guest) => guestToRemove !== guest.name);
    setGuestMap([...newMap]);
  };

  const displayError = (value: boolean) => {
    dispatch(setShowError(value));
  };

  const handleGetLocationUrl = () => selectedLocation?.waiverUrl ?? null;

  return {
    isWaiverLoading: isLoading,
    emailsSentSuccesfuly: isSuccess,
    emailsError: isError,
    guestMap,
    handleRemoveGuest,
    handleMakeAnotherBooking,
    handleAddNextGuest,
    accessToken,
    handleLogout,
    getLocationUrl: handleGetLocationUrl,
    handleSendWaiverLinks,
    displayError,
  };
};

export default useReservationComplete;
