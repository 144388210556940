import React, { FC, ReactNode } from 'react';
import { Box, Fade } from '@mui/material';
interface IFadeInTransition {
  children?: ReactNode;
  shouldStartTransition?: boolean;
}
const FadeInTransition: FC<IFadeInTransition> = ({ children, shouldStartTransition }) => (
  <Fade in={shouldStartTransition} timeout={400}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
      }}>
      {children}
    </Box>
  </Fade>
);

export default FadeInTransition;
