import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { sendPageView } from 'infrastructure/targets/web/common/tracking';

const PageChangeListener = () => {
  const location = useLocation();
  const currentUser = useAppSelector(selectUserSummary);

  useEffect(() => {
    const pathname = location.pathname;
    if (!pathname || pathname === '/') {
      return;
    }
    sendPageView(location.pathname, currentUser?.email ? currentUser : null);
  }, [location]);

  return null;
};

export default PageChangeListener;
