import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBackButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.text.primary,
  padding: theme.spacing(3, 0, 3, 0),
  marginBottom: theme.spacing(2),
  flex: '0 1 auto',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 0, 5, 0),
  },
  transform: 'all 0.3s',
  '&:hover': {
    opacity: 0.8,
  },
}));

export const StyledLink = styled('span')(() => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));
