import Womby from 'infrastructure/targets/web/assets/fonts/Womby-Regular.woff';
import Nunito400 from 'infrastructure/targets/web/assets/fonts/Nunito-Regular400.ttf';
import Nunito500 from 'infrastructure/targets/web/assets/fonts/Nunito-Medium500.ttf';
import Nunito700 from 'infrastructure/targets/web/assets/fonts/Nunito-Bold700.ttf';
import Nunito800 from 'infrastructure/targets/web/assets/fonts/Nunito-ExtraBold800.ttf';

export const fontOverrides = `
        @font-face {
          font-family: 'Womby';
          src: url('${Womby}') format('woff');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'Nunito';
          src: url('${Nunito400}') format('truetype');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'Nunito';
          src: url('${Nunito500}') format('truetype');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'Nunito';
          src: url('${Nunito700}') format('truetype');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Nunito';
          src: url('${Nunito800}') format('truetype');
          font-weight: 800;
          font-style: normal;
        }
      `;
