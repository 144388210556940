import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { FC, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import useCaseSelectLessonReservationDate from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectLessonReservationDate';
import useCaseSelectSimulatorReservationDate from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectSimulatorReservationDate';
import { handleDisabledDates } from 'infrastructure/components/BookingCalendar/helpers';
import { selectExperienceType } from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { getAdapterLocale } from 'infrastructure/targets/web/modules/common/helpers';

import { StyledDateInputField } from './style';

interface ISmallDatePicker {
  selectedDate?: string | undefined;
  onChange?: (date: Date) => void;
  availableNumberOfDaysToChooseFromToday?: number;
}

const SmallDatePicker: FC<ISmallDatePicker> = ({
  selectedDate,
  onChange,
  availableNumberOfDaysToChooseFromToday,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const flowType = useAppSelector(selectExperienceType);

  const { result: availableSimulatorDates, fetchFurtherAvailableDates: fetchSimulatorDates } =
    useCaseSelectSimulatorReservationDate();
  const { result: availableLessonDates, fetchFurtherAvailableDates: fetchLessonDates } =
    useCaseSelectLessonReservationDate();

  const dates =
    flowType === BookingWizardFlowTypes.Simulator || flowType === BookingWizardFlowTypes.Bowling
      ? availableSimulatorDates
      : availableLessonDates;

  const [dateValue, setDateValue] = useState<Date | undefined>(new Date());

  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      setDateValue(newValue);
    }
    if (onChange && newValue) {
      onChange(newValue);
    }
  };

  const debouncedLessonDateFetch = useDebouncedCallback((value) => {
    fetchLessonDates(value);
  }, 300);

  const debouncedSimDateFetch = useDebouncedCallback((value) => {
    fetchSimulatorDates(value);
  }, 300);

  useEffect(() => {
    if (selectedDate) {
      setDateValue(new Date(selectedDate));
      handleMonthChange(new Date(selectedDate));
    }
  }, [selectedDate]);

  const handleMonthChange = (value: Date | null) => {
    switch (flowType) {
      case BookingWizardFlowTypes.Lesson:
      case BookingWizardFlowTypes.FirstLesson:
        debouncedLessonDateFetch(value);
        break;

      case BookingWizardFlowTypes.Simulator:
      case BookingWizardFlowTypes.Bowling:
        debouncedSimDateFetch(value);
        break;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getAdapterLocale()}>
      {isMobile ? (
        <MobileDatePicker
          label="Date"
          value={dateValue}
          onChange={handleChange}
          onMonthChange={handleMonthChange}
          disablePast
          shouldDisableDate={(day) =>
            handleDisabledDates(day, dates, availableNumberOfDaysToChooseFromToday)
          }
          components={{
            OpenPickerIcon: CalendarMonthOutlinedIcon,
          }}
          renderInput={(params) => (
            <StyledDateInputField color="secondary" {...params} error={false} />
          )}
        />
      ) : (
        <DesktopDatePicker
          label="Date"
          value={dateValue}
          disablePast
          onMonthChange={handleMonthChange}
          shouldDisableDate={(day) =>
            handleDisabledDates(day, dates, availableNumberOfDaysToChooseFromToday)
          }
          components={{
            OpenPickerIcon: CalendarMonthOutlinedIcon,
          }}
          onChange={handleChange}
          renderInput={(params) => (
            <StyledDateInputField color="secondary" {...params} error={false} />
          )}
        />
      )}
    </LocalizationProvider>
  );
};
export default SmallDatePicker;
