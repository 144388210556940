import { Components, Theme } from '@mui/material/styles';

import typography from 'infrastructure/targets/web/theme/typography';
import { fontOverrides } from 'infrastructure/targets/web/theme/fonts';

import palette from './palette';

export default {
  MuiCssBaseline: {
    styleOverrides: fontOverrides,
  },
  MuiGrid: {
    styleOverrides: {
      'grid-xs-12': {
        paddingLeft: '0!important',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      body1: {
        ...typography.body1,
        color: palette?.text?.primary,
      },
      body2: {
        ...typography.body2,
        color: palette?.text?.primary,
      },
      h1: {
        ...typography.h1,
        color: palette?.text?.primary,
      },
      h2: {
        ...typography.h2,
        color: palette?.text?.primary,
      },
      h3: {
        ...typography.h3,
        color: palette?.text?.primary,
      },
      h4: {
        ...typography.h4,
        color: palette?.text?.primary,
      },
      h5: {
        ...typography.h5,
        color: palette?.text?.primary,
      },
      h6: {
        ...typography.h6,
        color: palette?.text?.primary,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderRadius: '8px',
        boxShadow: 'none',
        padding: '8px 16px',
        ...(ownerState.variant === 'text' && {
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.action.hover,
          },
          '&:disabled': {
            opacity: 0.4,
          },
        }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' && {
            '&:hover': {
              boxShadow: 'none',
            },
            '&:disabled': {
              opacity: 0.4,
              backgroundColor: theme?.palette?.primary?.main,
              color: theme?.palette?.primary?.contrastText,
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'secondary' && {
            '&:hover': {
              boxShadow: 'none',
            },
            '&:disabled': {
              opacity: 0.4,
              backgroundColor: theme?.palette?.secondary?.main,
              color: theme?.palette?.primary?.contrastText,
            },
          }),
      }),
      sizeSmall: {
        ...typography?.button?.small,
        height: '32px',
      },
      sizeMedium: {
        ...typography?.button?.medium,
        height: '40px',
      },
      sizeLarge: {
        ...typography?.button?.large,
        height: '48px',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root': {
          borderRadius: '8px',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        '& .MuiSelect-icon': {
          display: 'none',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
        '&.Mui-focused': {
          color: theme.palette.text.secondary,
        },
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `1px solid ${theme.palette.other.outlineBorder}`,
        borderRadius: '8px',
        boxShadow: `0px 59px 24px rgba(201, 201, 201, 0.02),
          0px 33px 20px rgba(201, 201, 201, 0.07),
          0px 15px 15px rgba(201, 201, 201, 0.12),
          0px 4px 8px rgba(201, 201, 201, 0.14),
          0px 0px 0px rgba(201, 201, 201, 0.14)`,
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `1px solid ${theme.palette.other.outlineBorder}`,
        color: theme.palette.text.primary,
        borderRadius: '8px',
        textTransform: 'none',
        display: 'flex',
        ...typography.body1,
        flexDirection: 'row',
        padding: '11px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
          borderTopRightRadius: '8px !important',
          borderBottomRightRadius: '8px !important',
        },
        '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderTopLeftRadius: '8px !important',
          borderBottomLeftRadius: '8px !important',
          marginLeft: '0px !important',
          borderLeft: `1px solid ${theme.palette.other.outlineBorder} !important`,
        },
        '&.Mui-selected': {
          display: 'inline-flex',
          border: '1px solid transparent',
          color: `${theme.palette.secondary.contrastText} !important`,
          backgroundColor: theme.palette.secondary?.main,
          '&:hover': {
            color: `${theme.palette.secondary.contrastText} !important`,
            backgroundColor: theme.palette.secondary?.main,
            opacity: 0.8,
            '@media (hover: none)': {
              backgroundColor: theme.palette.secondary?.main,
              color: `${theme.palette.secondary.contrastText} !important`,
              '&:active': {
                backgroundColor: theme.palette.secondary?.main,
              },
            },
          },
        },
        '&:hover': {
          color: `${theme.palette.text.primary} !important`,
          backgroundColor: '#14CEC614',
        },
      }),
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.action.active,
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        marginLeft: theme.spacing(2),
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.error.main,
      }),
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: (props: { theme: Omit<Theme, 'components'> } & Record<string, unknown>) => ({
        backgroundColor: 'transparent',
        '&.Mui-selected': {
          backgroundColor: props.theme.palette.secondary?.main,
          '&:hover, :focus': {
            backgroundColor: props.theme.palette.secondary?.main,
          },
        },
      }),
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: (props: { theme: Omit<Theme, 'components'> } & Record<string, unknown>) => ({
        display: 'flex',
        paddingRight: props.theme.spacing(4),
        paddingLeft: props.theme.spacing(4),
        '& .MuiPickersArrowSwitcher-spacer': {
          display: 'none',
        },
      }),
    },
  },
  MuiPickerStaticWrapper: {
    styleOverrides: {
      content: {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&:focus': {
            backgroundColor: theme.palette.action.focus,
          },
        },
        height: '48px',
        padding: '0px 12px',
      }),
    },
  },
} as Components<Omit<Theme, 'components'>>;
