import { TypographyOptions } from '@mui/material/styles/createTypography';

import { themeBreakpoints } from './breakpoints';

const HEADING1_FONT_FAMILY = 'Womby, sans-serif';
const HEADING2_FONT_FAMILY = 'Nunito, sans-serif';

const HEADING_FONT_SIZE_H1 = { mobile: 56, desktop: 56 };
const HEADING_LINE_HEIGHT_H1 = { mobile: 72, desktop: 72 };

const HEADING_FONT_SIZE_H2 = { mobile: 48, desktop: 48 };
const HEADING_LINE_HEIGHT_H2 = { mobile: 64, desktop: 64 };
const HEADING_FONT_SIZE_H3 = { mobile: 32, desktop: 32 };
const HEADING_LINE_HEIGHT_H3 = { mobile: 48, desktop: 48 };

const HEADING_FONT_SIZE_H4 = { mobile: 24, desktop: 24 };
const HEADING_LINE_HEIGHT_H4 = { mobile: 36, desktop: 36 };

const HEADING_FONT_SIZE_H5 = { mobile: 20, desktop: 20 };
const HEADING_LINE_HEIGHT_H5 = { mobile: 30, desktop: 30 };

const HEADING_FONT_SIZE_H6 = { mobile: 18, desktop: 18 };
const HEADING_LINE_HEIGHT_H6 = { mobile: 28, desktop: 28 };

const BODY_FONT_FAMILY = 'Nunito, sans-serif';

const BODY_FONT_WEIGHT_REGULAR = 400;
const BODY_FONT_WEIGHT_MEDIUM = 500;
const BODY_FONT_WEIGHT_BOLD = 700;
const BODY_FONT_WEIGHT_EXTRABOLD = 800;

const BODY1_FONT_SIZE = { mobile: 16, desktop: 16 };
const BODY1_LINE_HEIGHT = { mobile: 24, desktop: 26 };

const BODY2_FONT_SIZE = { mobile: 14, desktop: 14 };
const BODY2_LINE_HEIGHT = { mobile: 22, desktop: 24 };

const BODY3_FONT_SIZE = { mobile: 12, desktop: 12 };
const BODY3_LINE_HEIGHT = { mobile: 20, desktop: 20 };

const CAPTION_FONT_SIZE = { mobile: 14, desktop: 12 };
const CAPTION_LINE_HEIGHT = { mobile: 22, desktop: 18 };

const HTML_FONT_SIZE = 16; // Browser default (better not to change)
const BODY_FONT_SIZE = 14; // Browser default (better not to change)

const pxToRem = (px: number) => `${(px / HTML_FONT_SIZE) * (BODY_FONT_SIZE / 14)}rem`;
const lineHeightPxToUnitless = (lineHeightPx: number, fontSizePx: number) =>
  lineHeightPx / fontSizePx;

export default {
  htmlFontSize: HTML_FONT_SIZE,
  fontSize: BODY_FONT_SIZE,

  fontFamily: BODY_FONT_FAMILY,
  fontWeightLight: undefined,
  fontWeightRegular: BODY_FONT_WEIGHT_REGULAR,
  fontWeightMedium: BODY_FONT_WEIGHT_MEDIUM,
  fontWeightExtraBold: BODY_FONT_WEIGHT_EXTRABOLD,
  fontWeightBold: BODY_FONT_WEIGHT_BOLD,

  h1: {
    fontFamily: HEADING1_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(HEADING_FONT_SIZE_H1.mobile),
    lineHeight: lineHeightPxToUnitless(HEADING_LINE_HEIGHT_H1.mobile, HEADING_FONT_SIZE_H1.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H1.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H1.desktop,
        HEADING_FONT_SIZE_H1.desktop,
      ),
    },
  },
  h2: {
    fontFamily: HEADING1_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(HEADING_FONT_SIZE_H2.mobile),
    lineHeight: lineHeightPxToUnitless(HEADING_LINE_HEIGHT_H2.mobile, HEADING_FONT_SIZE_H2.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H2.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H2.desktop,
        HEADING_FONT_SIZE_H2.desktop,
      ),
    },
  },
  h3: {
    fontFamily: HEADING2_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_EXTRABOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H3.mobile),
    lineHeight: lineHeightPxToUnitless(HEADING_LINE_HEIGHT_H3.mobile, HEADING_FONT_SIZE_H3.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H3.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H3.desktop,
        HEADING_FONT_SIZE_H3.desktop,
      ),
    },
  },
  h4: {
    fontFamily: HEADING2_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_EXTRABOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H4.mobile),
    lineHeight: lineHeightPxToUnitless(HEADING_LINE_HEIGHT_H4.mobile, HEADING_FONT_SIZE_H4.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H4.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H4.desktop,
        HEADING_FONT_SIZE_H4.desktop,
      ),
    },
  },
  h5: {
    fontFamily: HEADING2_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_BOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H5.mobile),
    lineHeight: lineHeightPxToUnitless(HEADING_LINE_HEIGHT_H5.mobile, HEADING_FONT_SIZE_H5.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H5.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H5.desktop,
        HEADING_FONT_SIZE_H5.desktop,
      ),
    },
  },
  h6: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_BOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H6.mobile),
    lineHeight: lineHeightPxToUnitless(HEADING_LINE_HEIGHT_H6.mobile, HEADING_FONT_SIZE_H6.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H6.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H6.desktop,
        HEADING_FONT_SIZE_H6.desktop,
      ),
    },
  },
  body1: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_MEDIUM,
    fontSize: pxToRem(BODY1_FONT_SIZE.mobile),
    lineHeight: lineHeightPxToUnitless(BODY1_LINE_HEIGHT.mobile, BODY1_FONT_SIZE.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(BODY1_FONT_SIZE.desktop),
      lineHeight: lineHeightPxToUnitless(BODY1_LINE_HEIGHT.desktop, BODY1_FONT_SIZE.desktop),
    },
  },
  body2: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_MEDIUM,
    fontSize: pxToRem(BODY2_FONT_SIZE.mobile),
    lineHeight: lineHeightPxToUnitless(BODY2_LINE_HEIGHT.mobile, BODY2_FONT_SIZE.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(BODY2_FONT_SIZE.desktop),
      lineHeight: lineHeightPxToUnitless(BODY2_LINE_HEIGHT.desktop, BODY2_FONT_SIZE.desktop),
    },
  },
  caption: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(CAPTION_FONT_SIZE.mobile),
    lineHeight: lineHeightPxToUnitless(CAPTION_LINE_HEIGHT.mobile, CAPTION_FONT_SIZE.mobile),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(CAPTION_FONT_SIZE.desktop),
      lineHeight: lineHeightPxToUnitless(CAPTION_LINE_HEIGHT.desktop, CAPTION_FONT_SIZE.desktop),
    },
  },
  button: {
    large: {
      fontFamily: BODY_FONT_FAMILY,
      fontWeight: BODY_FONT_WEIGHT_BOLD,
      fontSize: pxToRem(BODY1_FONT_SIZE.desktop),
      lineHeight: lineHeightPxToUnitless(BODY1_LINE_HEIGHT.desktop, BODY1_FONT_SIZE.desktop),
      letterSpacing: 0,
      textTransform: 'none',
    },
    medium: {
      fontFamily: BODY_FONT_FAMILY,
      fontWeight: BODY_FONT_WEIGHT_BOLD,
      fontSize: pxToRem(BODY2_FONT_SIZE.desktop),
      lineHeight: lineHeightPxToUnitless(BODY2_LINE_HEIGHT.desktop, BODY2_FONT_SIZE.desktop),
      letterSpacing: 0,
      textTransform: 'none',
    },
    small: {
      fontFamily: BODY_FONT_FAMILY,
      fontWeight: BODY_FONT_WEIGHT_BOLD,
      fontSize: pxToRem(BODY3_FONT_SIZE.desktop),
      lineHeight: lineHeightPxToUnitless(BODY3_LINE_HEIGHT.desktop, BODY3_FONT_SIZE.desktop),
      letterSpacing: 0,
      textTransform: 'none',
    },
  },
  body3: {},
} as TypographyOptions;
