import { createContext } from 'react';

interface TextCopyContext {
  getTextCopy: (text: string) => string;
}

export const TextCopyContextStub: TextCopyContext = {
  getTextCopy: () => '',
};

export const TextCopyContext = createContext<TextCopyContext>(TextCopyContextStub);
