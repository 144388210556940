import { StoreTypes } from 'common';
import { useInjection } from 'inversify-react';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';

export type IGetFoodItemListHook = InjectableHook<AsyncHookResult<StoreTypes.Product, unknown>>;

export const GetFoodItemList = Symbol('GetFoodItemList');

export type IGetFoodItemList = InjectableHook<
  AsyncHookResult<StoreTypes.ProductResponse, unknown> & {
    fetchFoodItemList: (
      categoryId: string,
      pagination?: { cursor?: string | null; limit?: number },
    ) => void;
    fetchFoodCategoryList: () => void;
    foodCategoryListInProgress: boolean;
    foodCategoryListError?: unknown;
    foodCategoryList: StoreTypes.ProductCategoryResponse | undefined;
  }
>;

const useCaseGetFoodItemList = () => {
  const adapter = useInjection<IGetFoodItemList>(GetFoodItemList);
  const {
    error,
    result,
    inProgress,
    fetchFoodItemList,
    fetchFoodCategoryList,
    foodCategoryListInProgress,
    foodCategoryListError,
    foodCategoryList,
  } = adapter();

  return {
    inProgress,
    error,
    result: result?.products,
    nextPageCursor: result?.nextPageCursor,
    fetchFoodItemList,
    fetchFoodCategoryList,
    foodCategoryList: foodCategoryList,
    foodCategoryListInProgress,
    foodCategoryListError,
  };
};

export default useCaseGetFoodItemList;
