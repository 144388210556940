import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import React, { FC } from 'react';

export interface IStyledToggleButtonGroup extends ToggleButtonGroupProps {
  numberofbuttons: number;
  shouldstayinonerow?: number;
}

export const ExtendedToggleButtonGroup: FC<IStyledToggleButtonGroup> = (props) => (
  <ToggleButtonGroup {...props}>{props.children}</ToggleButtonGroup>
);
