export const mockHourButtons = [
  {
    title: '8:00 AM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      8,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '9:00 AM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      9,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '10:00 AM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      10,
      0,
      0,
    ).toUTCString(),
    isDisabled: true,
  },
  {
    title: '11:00 AM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      11,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '12:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      12,
      0,
      0,
    ).toUTCString(),
    isDisabled: true,
  },
  {
    title: '1:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      13,
      0,
      0,
    ).toUTCString(),
    isDisabled: true,
  },
  {
    title: '2:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      14,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '3:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      15,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '4:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      16,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '5:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      17,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '6:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      18,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '7:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      19,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '8:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      20,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '9:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      21,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '10:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      22,
      0,
      0,
    ).toUTCString(),
  },
  {
    title: '11:00 PM',
    value: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay(),
      23,
      0,
      0,
    ).toUTCString(),
    isDisabled: true,
  },
];

export const mockButtonsWithAdditionalInfo = [
  {
    title: '0.5 Hour',
    value: '0.5 Hour',
    subtitle: '$35',
  },
  {
    title: '1 Hour',
    value: '1 Hour',
    subtitle: '$75',
  },
  {
    title: 'Small Group',
    value: 'small_group',
    subtitle: '$155',
  },
];

export const mockButtons = [
  {
    title: '0.5 Hours',
    value: 0.5,
  },
  {
    title: '1 Hour',
    value: 1,
  },
  {
    title: 'Small group',
    value: 'small_group',
  },
];

export const mockPartySizeButtons = [
  {
    title: '1',
    value: '1',
  },
  {
    title: '2',
    value: '2',
  },
  {
    title: '3',
    value: '3',
  },
  {
    title: '4',
    value: '4',
  },
  {
    title: '5',
    value: '5',
  },
  {
    title: '6',
    value: '6',
  },
  {
    title: '7-12',
    value: '7-12',
  },
  {
    title: '13+',
    value: '13+',
  },
];

export const mockSessionLengthHours = [
  {
    title: '0.5 Hour',
    value: 0.5,
  },
  {
    title: '1 Hour',
    value: 1,
    isDisabled: true,
  },
  {
    title: '1.5 Hours',
    value: 1.5,
  },
  {
    title: '2 Hours',
    value: 2,
  },
  {
    title: '2.5 Hours',
    value: 2.5,
  },
  {
    title: '3 Hours',
    value: 3,
    isDisabled: true,
  },
  {
    title: '3.5 Hours',
    value: 3.5,
  },
  {
    title: '4 Hours',
    value: 4,
  },
];
