import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { FC, useState } from 'react';

export interface SessionHourMenuItem {
  title: string;
  value: string;
}

interface ISessionHourSelect {
  sessionHourItems: Array<SessionHourMenuItem>;
  onChange?: (sessionHour: string) => void;
}
const SessionHourSelect: FC<ISessionHourSelect> = ({ onChange, sessionHourItems }) => {
  const [sessionHour, setSessionHour] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    const sessionHourValue = event.target.value as string;
    setSessionHour(sessionHourValue);
    if (onChange) {
      onChange(sessionHourValue);
    }
  };

  return (
    <FormControl fullWidth sx={{ minWidth: '100px', flex: 2 }}>
      <InputLabel id="session_hour_select">Time Slot</InputLabel>
      <Select
        labelId="session_hour_select"
        sx={{
          '& .MuiSelect-icon': {
            display: 'block',
          },
        }}
        id="session_hour_select"
        name="sessionHour"
        value={sessionHour}
        label="Time Slot"
        color="secondary"
        onChange={handleChange}>
        {sessionHourItems?.map((size) => (
          <MenuItem key={size.title} value={size.value}>
            {size.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default SessionHourSelect;
