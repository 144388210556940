import React from 'react';
import { useTheme } from '@mui/material';
import { BounceLoader } from 'react-spinners';

const Loader = () => {
  const theme = useTheme();
  return <BounceLoader color={theme.palette.secondary.light} />;
};

export default Loader;
