import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledHorizontalCardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 4, 4, 4),
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'transparent',
  marginBottom: theme.spacing(4),
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '8px',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '100%',
  },
}));

export const StyledInnerRightCardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 4),
  justifyContent: 'center',
  gap: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 0, 0, 0),
    maxWidth: '100%',
  },
}));

export const StyledTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

export const StyledContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.secondary,
}));

export const StyledBottomContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
