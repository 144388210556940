import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { useInjection } from 'inversify-react';
import { useEffect } from 'react';

import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';
import { ExternalNavigationHook, IExternalNavigationHook } from './useCaseNavigateTo';

export enum PaymentDetailsError {
  Unknown = 'unknown',
  InvalidPaymentInfo = 'invalid_payment_info',
}

export interface PaymentDetailsForm {
  expirationDate: string;
  id: string;
  email: string;
  country: string;
  address: string;
  cardHolder: string;
  cardNumber: string;
  cardType: string;
  city: string;
  expMonth: string;
  expYear: string;
  postalCode: string;
  state: string;
  ccv: string;
}

export enum KnownCardTypes {
  AmericanExpress = 'americanExpress',
}

export const PaymentDetailsAdapter = Symbol('PaymentDetailsAdapter');
export type IPaymentDetailsAdapter = InjectableHook<
  AsyncHookResult<unknown, PaymentDetailsError> & {
    updatePaymentData: (locationId: string, values: PaymentDetailsForm) => Promise<void>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    countries: Array<any>;
    accessToken: string | null;
    isSuccess: boolean;
    hasCreditCardOnFile: boolean;
    flowType: string | undefined;
    fetchUserData: () => void;
    fetchedUserEmail: string;
  }
>;

const useCasePaymentDetails = () => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );

  const externalNavigation = useHookInjection<IExternalNavigationHook>(ExternalNavigationHook);
  const adapter = useInjection<IPaymentDetailsAdapter>(PaymentDetailsAdapter);
  const {
    error,
    result,
    inProgress,
    updatePaymentData,
    countries,
    accessToken,
    isSuccess,
    hasCreditCardOnFile,
    flowType,
    fetchUserData,
    fetchedUserEmail,
  } = adapter();

  const handleNextStep = async (locationId: string, values: PaymentDetailsForm) => {
    await updatePaymentData(locationId, values);
    await fetchUserData();
  };

  const handlePrevStep = () => {
    if (hasCreditCardOnFile) {
      bookingWizardNavigation.redirectToStep(WizardStep.confirmReservation);
    } else {
      switch (flowType) {
        case BookingWizardFlowTypes.Lesson:
        case BookingWizardFlowTypes.FirstLesson:
          bookingWizardNavigation.redirectToStep(WizardStep.chooseLessonTime);
          break;
        case BookingWizardFlowTypes.Simulator:
        case BookingWizardFlowTypes.Bowling:
          bookingWizardNavigation.redirectToStep(WizardStep.sessionLength);
          break;
      }
    }
  };

  useEffect(() => {
    if ((!error && result) || isSuccess) {
      bookingWizardNavigation.redirectToStep(WizardStep.confirmReservation);
    }
  }, [error, result]);

  useEffect(() => {
    if (!accessToken || accessToken === '') {
      externalNavigation && externalNavigation.redirectToSignInPage();
    }
  }, [accessToken]);

  return {
    fetchedUserEmail,
    inProgress,
    error,
    result,
    countryList: countries,
    nextStep: handleNextStep,
    prevStep: handlePrevStep,
  };
};

export default useCasePaymentDetails;
