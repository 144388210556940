import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'infrastructure/redux/store';

import { PaymentForm, PromoCodeType } from './payment.slice';

export const selectSelf = (storeState: RootState): RootState => storeState;

export const selectPaymentForm = createSelector(
  selectSelf,
  (state: RootState): PaymentForm => state.paymentForm,
);

export const selectTotalAmountToPay = createSelector(
  selectPaymentForm,
  (paymentForm: PaymentForm): number | undefined => paymentForm.total,
);

export const selectPointsPrice = createSelector(
  selectPaymentForm,
  (
    paymentForm: PaymentForm,
  ): { pointsPrice: number | undefined; pointsSmallFeePrice: number | undefined } => ({
    pointsPrice: paymentForm.pointsPrice,
    pointsSmallFeePrice: paymentForm.pointsSmallFeePrice,
  }),
);

export const selectPointsToEarn = createSelector(
  selectPaymentForm,
  (paymentForm: PaymentForm): number | undefined => paymentForm.pointsToEarn,
);

export const selectPayByPoints = createSelector(
  selectPaymentForm,
  (paymentForm: PaymentForm): boolean | undefined => paymentForm.payByPoints,
);

export const selectPromoCodeData = createSelector(
  selectPaymentForm,
  (
    paymentForm: PaymentForm,
  ):
    | {
        code: string;
        codeType: PromoCodeType;
        amount: number;
        isDaily: boolean;
      }
    | undefined => paymentForm.promoCodeData,
);
