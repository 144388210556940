import React, { FC } from 'react';

import useCaseNavigateBookingWizard from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import { WizardStep } from 'domain/entities/WizardStep';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import PricingSummary from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/PricingSummary';

import { StyledSection500Column } from './style';

interface IMobilePricingStep {
  stepName: WizardStep;
}

const MobilePricingStep: FC<IMobilePricingStep> = ({ stepName }) => {
  const { moveToStep } = useCaseNavigateBookingWizard(stepName);
  return (
    <PageLayout pageTitle={'Pricing'} shouldFadeIn>
      <NavigationBar backButtonCallback={() => moveToStep(WizardStep.sessionLength)} />
      <StyledSection500Column>
        <PricingSummary />
      </StyledSection500Column>
    </PageLayout>
  );
};

export default MobilePricingStep;
