import { Box, Button, CircularProgress, Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import valid from 'card-validator';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { format, formatDuration } from 'date-fns';
import { BookingWizardFlowTypes } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import useCasePaymentDetails, {
  KnownCardTypes,
  PaymentDetailsError,
  PaymentDetailsForm,
} from 'application/modules/bookingWizard/useCases/hooks/useCasePaymentDetails';
import { WizardStep } from 'domain/entities/WizardStep';
import ContentCard from 'infrastructure/components/ContentCard';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import {
  selectBookingForm,
  selectExperienceType,
  selectLocation,
  selectLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { StyledSection500Column } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SignInStep/style';
import FormikInput from 'infrastructure/targets/web/modules/common/FormikInput';
import FormikSelect from 'infrastructure/targets/web/modules/common/FormikSelect';
import {
  findDebitCardType,
  getCurrency,
  isCardTypeAmex,
  mapExperienceTypeToItemDetails,
} from 'infrastructure/targets/web/modules/common/helpers';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';
import {
  selectPromoCodeData,
  selectTotalAmountToPay,
} from 'infrastructure/redux/slices/payment.selector';

interface IPaymentDetailsStep {
  stepName: WizardStep;
}

interface AdditionalCardInfo {
  cardType: string;
  expMonth: string;
  expYear: string;
}

const isUAE = import.meta.env.VITE_SENTRY_ENV.endsWith('_ae');

const PaymentDetailsStep: FC<IPaymentDetailsStep> = () => {
  const { nextStep, prevStep, inProgress, fetchedUserEmail, error, countryList } =
    useCasePaymentDetails();
  const user = useAppSelector(selectUserSummary);
  const locationId = useAppSelector(selectLocation);
  const { reservationDate, startTime, sessionLength, partySize } =
    useAppSelector(selectBookingForm);
  const [additionalCardInfo, setAdditionalCardInfo] = useState<AdditionalCardInfo>();
  const selectOptions = countryList?.map((country) => ({
    value: country.value,
    label: country.label,
  }));
  const [cardMask, setCardMask] = useState('0000-0000-0000-0000');
  const livePromoCodeData = useAppSelector(selectPromoCodeData);
  const locationDetails = useAppSelector(selectLocationDetails);
  const experienceType = useAppSelector(selectExperienceType);
  const total = useAppSelector(selectTotalAmountToPay);
  const { sendEvent } = useEventTracking();

  const validationSchema = useMemo(
    () =>
      object().shape({
        cardHolder: string().required('Please provide your name'),
        cardNumber: string()
          .test(
            'test-card-number',
            'Credit Card number is invalid',
            (value) => valid.number(value).isValid,
          )
          .required('Please provide card number'),
        expirationDate: string()
          .typeError('Not a valid expiration date. Example: MM/YYYY')
          .max(7, 'Not a valid expiration date. Example: MM/YYYY')
          .matches(/([0-9]{2})\/([0-9]{4})/, 'Not a valid expiration date. Example: MM/YYYY')
          .test(
            'test-credit-card-expiration-date',
            'Not a valid expiration date. Provided date is in the past',
            (expirationDate) => {
              if (!expirationDate) {
                return false;
              }

              const today = new Date();
              const monthToday = today.getMonth() + 1;
              const yearToday = today.getFullYear().toString();

              const [expMonth, expYear] = expirationDate.split('/');

              if (Number(expYear) < Number(yearToday)) {
                return false;
              } else if (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday)) {
                return false;
              }

              return true;
            },
          )
          .test(
            'test-credit-card-expiration-date',
            'Not a valid expiration date month ',
            (expirationDate) => {
              if (!expirationDate) {
                return false;
              }
              const [expMonth] = expirationDate.split('/');

              if (Number(expMonth) > 12) {
                return false;
              }

              return true;
            },
          )
          .required('Expiration date is required'),
        ccv: string()
          .min(3, 'CCV must be between 3 and 4 characters')
          .max(4, 'CCV must be between 3 and 4 characters')
          .required('Please provide CCV code from back of your card'),
        address: isUAE ? string().notRequired() : string().required('Please provide your address'),
        city: isUAE ? string().notRequired() : string().required('Please provide your city'),
        country: isUAE ? string().notRequired() : string().required('Please select country'),
        state: isUAE ? string().notRequired() : string().required('Please provide state'),
        postalCode: isUAE
          ? string().notRequired()
          : string().required('Please provide your postal code'),
      }),
    [],
  );

  const paymentDetailsForm = useFormik<PaymentDetailsForm>({
    initialValues: {
      id: user?.clientId || '',
      email: user?.email || fetchedUserEmail,
      address: user?.creditCard?.address || '',
      country: isUAE ? 'AE' : 'US',
      cardHolder: user?.creditCard?.cardHolder || '',
      cardNumber: '',
      expirationDate: '',
      cardType: user?.creditCard?.cardType || '',
      city: user?.creditCard?.city || '',
      expMonth: user?.creditCard?.expMonth || '',
      expYear: user?.creditCard?.expYear || '',
      postalCode: user?.creditCard?.postalCode || '',
      state: user?.creditCard?.state || '',
      ccv: '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      if (!values.email || !values.id) {
        if (user?.email && user?.clientId) {
          values.email = user.email || fetchedUserEmail;
          values.id = user.clientId;
        } else {
          Sentry.captureException(
            new Error('UpdateBilling - User email or clientId is missing from the store'),
          );
        }
      }
      if (locationId) {
        nextStep(locationId, {
          ...values,
          cardType: isCardTypeAmex(values.cardNumber)
            ? KnownCardTypes.AmericanExpress
            : values.cardType,
        });

        sendEvent(
          EventType.AddShippingInfo,
          {
            date: format(new Date(reservationDate as unknown as Date), 'P'),
            party_size: partySize,
            location: locationDetails?.name,
            session_start: startTime && format(new Date(startTime.slice(0, -1)), 'hh:mm aa'),
            session_duration: formatDuration(
              { hours: Number(sessionLength) },
              { format: ['hours'], zero: true },
            ),
            value: total,
            currency: getCurrency(),
            coupon: livePromoCodeData?.code || null,
            payment_type: 'Credit Card',
            payment_method: values.cardType,
            checkout_section: 'Step 1: Shipping Method Completed',
          },
          [
            {
              ...mapExperienceTypeToItemDetails(experienceType as BookingWizardFlowTypes),
              currency: getCurrency(),
              index: 0,
              location_id: 'Product Listing',
              price: total,
              quantity: 1,
            },
          ],
        );
      }
    },
  });

  useEffect(() => {
    if (error) {
      switch (error) {
        case PaymentDetailsError.Unknown:
          paymentDetailsForm.setErrors({ postalCode: 'Something went wrong. Please try again.' });
          break;
        case PaymentDetailsError.InvalidPaymentInfo:
          paymentDetailsForm.setErrors({
            postalCode: 'The provided credit card info is invalid.',
          });
          break;
        default:
          break;
      }
    }
  }, [error]);

  useEffect(() => {
    const cardNumber = paymentDetailsForm.values['cardNumber'];
    const expMonth = paymentDetailsForm.values['expirationDate'].split('/')[0];
    const expYear = paymentDetailsForm.values['expirationDate'].split('/')[1];
    const cardType = valid.number(cardNumber).card?.type;
    setCardMask(findDebitCardType(cardNumber));
    setAdditionalCardInfo({
      cardType: cardType || '',
      expMonth,
      expYear,
    });
  }, [paymentDetailsForm.values]);

  useEffect(() => {
    paymentDetailsForm.setFieldValue('expMonth', additionalCardInfo?.expMonth);
    paymentDetailsForm.setFieldValue('expYear', additionalCardInfo?.expYear);
    paymentDetailsForm.setFieldValue('cardType', additionalCardInfo?.cardType);
  }, [additionalCardInfo]);

  return (
    <PageLayout pageTitle={'Check Out'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep} />
      <StyledSection500Column>
        <FormikProvider value={paymentDetailsForm}>
          <ContentCard title={'Payment Details'}>
            <Typography variant={'body1'} sx={{ mt: 3, mb: 0 }}>
              Please add a card to your profile to complete the reservation.
            </Typography>
            <Typography variant={'body1'} sx={{ mt: 0, mb: 7 }}>
              You will not be charged until you arrive for your booking.
            </Typography>
            <FormikInput
              variant="outlined"
              color="secondary"
              type="text"
              name="cardHolder"
              noFormLabel
              label={'Name on Card'}
              sx={{ mb: 5, width: '100%' }}
            />
            <FormikInput
              variant="outlined"
              color="secondary"
              inputProps={{ maxLength: 19 }}
              type="text"
              name="cardNumber"
              inputMask={cardMask}
              noFormLabel
              label={'Card Number'}
              sx={{ mb: 5, width: '100%' }}
            />

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 3 }}>
              <FormikInput
                variant="outlined"
                color="secondary"
                type="text"
                name="expirationDate"
                inputMask="00/0000"
                noFormLabel
                label={'Expiration Date'}
                sx={{ mb: 5, width: '100%' }}
              />
              <FormikInput
                variant="outlined"
                color="secondary"
                type="text"
                onChange={(event) => {
                  /[0-9]/.test(event.target.value)
                    ? paymentDetailsForm.setFieldValue('ccv', event.target.value)
                    : paymentDetailsForm.setFieldValue('ccv', '');
                }}
                name="ccv"
                inputProps={{ maxLength: 4 }}
                noFormLabel
                label={'CCV'}
                sx={{ mb: 5, width: '100%' }}
              />
            </Box>
            <FormikInput
              variant="outlined"
              color="secondary"
              type="text"
              name="address"
              noFormLabel
              label={'Address'}
              sx={{ mb: 5, width: '100%' }}
            />
            <FormikInput
              variant="outlined"
              color="secondary"
              type="text"
              name="city"
              noFormLabel
              label={'City'}
              sx={{ mb: 5, width: '100%' }}
            />
            <FormikSelect
              variant="outlined"
              color="secondary"
              type="text"
              options={selectOptions}
              name="country"
              noFormLabel
              sx={{ mb: 5, width: '100%' }}
            />
            <FormikInput
              variant="outlined"
              color="secondary"
              type="text"
              name="state"
              noFormLabel
              label={'State / Province / Region'}
              sx={{ mb: 5, width: '100%' }}
            />
            <FormikInput
              variant="outlined"
              color="secondary"
              type="text"
              name="postalCode"
              noFormLabel
              inputProps={{ maxLength: 15 }}
              label={'Postal Code'}
              sx={{ mb: { xs: 9, sm: 5 }, width: '100%' }}
            />
            <Button
              variant="contained"
              color="primary"
              className="nextButton"
              size="large"
              onClick={() => paymentDetailsForm.handleSubmit()}
              disabled={
                !paymentDetailsForm.isValid ||
                (Object.keys(paymentDetailsForm.touched).length === 0 &&
                  paymentDetailsForm.touched.constructor === Object)
              }>
              {inProgress ? <CircularProgress sx={{ color: 'primary.contrastText' }} /> : 'Save'}
            </Button>
          </ContentCard>
        </FormikProvider>
      </StyledSection500Column>
    </PageLayout>
  );
};

export default PaymentDetailsStep;
