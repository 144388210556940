import { h, t } from 'infrastructure/targets/web/modules/common/helpers';

const getAPIBaseURL = () =>
  process.env.SENTRY_ENV?.search(/production/) === 0
    ? 'https://fiveirongolf.com/wp-admin/admin-ajax.php'
    : location.host.search(/localhost/) == 0
    ? 'http://wordpress.test/wp-admin/admin-ajax.php'
    : 'https://fivedevelop.wpengine.com/wp-admin/admin-ajax.php';

const useActiveCampaignContactConversion = () => {
  const newEmail = localStorage.getItem('new_email');
  const email = localStorage.getItem('email');
  const conversionSet = localStorage.getItem('conversion_sent');

  const shared_secret = import.meta.env.VITE_MARKETING_SITE_SHARED_SECRET;
  if (newEmail && email && !conversionSet) {
    localStorage.setItem('conversion_sent', 'true');
    const baseUrl = getAPIBaseURL();
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Private ' + h(shared_secret + t(new Date().getTime())),
      'X-Requested-With': 'XMLHttpRequest',
    };
    const data = new URLSearchParams();
    data.append('action', 'activecampaign_api_bridge');
    data.append('call', 'transfer_history');
    data.append('oldUserEmail', email);
    data.append('newUserEmail', newEmail);
    fetch(baseUrl, {
      method: 'POST',
      headers: headers,
      body: data,
    })
      .then((resp) => resp.json())
      .then(function () {
        const new_email = localStorage.getItem('new_email');
        if (new_email && new_email !== "undefined") {
          localStorage.setItem('email', new_email);
          localStorage.removeItem('new_email');
        }
      });
  }
};

export default useActiveCampaignContactConversion;
