import { Alert, styled } from '@mui/material';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  flexDirection: 'row',
  maxWidth: '750px',
  padding: theme.spacing(4),
  borderRadius: '8px',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: '62px',
    marginBottom: '0px',
  },
}));
