import React, { FC, ReactNode } from 'react';

import BackButton from 'infrastructure/components/BackButton';

import { StyledInputContainer, StyledNavigationBar } from './style';

interface INavigationBar {
  children?: ReactNode;
  backButtonCallback?: () => void;
  noBackButton?: boolean;
  inline?: boolean;
}
const NavigationBar: FC<INavigationBar> = ({
  children,
  backButtonCallback,
  noBackButton,
  inline = false,
}) => (
  <StyledNavigationBar
    sx={{ color: 'secondary.contrast', flexDirection: inline ? 'row' : 'column' }}>
    {!noBackButton && <BackButton backButtonCallback={backButtonCallback} />}
    <StyledInputContainer>{children}</StyledInputContainer>
  </StyledNavigationBar>
);

export default NavigationBar;
