import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSection500Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '500px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
