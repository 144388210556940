import { FeatureCarouselItem } from 'domain/entities/FeatureCarousel';

export const initialCarouselItems: FeatureCarouselItem[] = [
  {
    id: 1,
    title: 'Swing Evaluation',
    description: '',
    image:
      'https://firebasestorage.googleapis.com/v0/b/i-mobile-app.appspot.com/o/FeatureCarousel%2FSwingEvaluation.jpg?alt=media&token=5bb5a7e3-4190-48eb-9cc2-8f890e4eddb2',
    link: {
      US: 'SWING_EVAL',
      UAE: 'SWING_EVAL',
      AU: 'SWING_EVAL',
    },
    locations: [
      // US locations
      '83d8c48c-a276-4258-b3ff-c904989de587',
      '8d050585-8094-4a66-a18a-df3e2edec2b9',
      'fbe1ce1b-8f48-430b-8015-da1c15f95f11',
      '027ecf74-3543-4243-9bf8-5aee1a64d2e5',
      'dd3ab333-b409-4879-b441-f28dd121f9ac',
      '60bcd77a-071f-4456-9f90-1f803d8780cb',
      '072c24bb-125e-4894-a155-616db85c9aec',
      '4388c520-a4de-4d49-b812-e2cb4badf667',
      '2626f492-d3f2-47be-ab2f-890d3775c963',
      '31f9eb4b-7fa7-4073-9c36-132b626c8b7e',
      'c71d765c-c7fd-4be7-aaba-2f3b21a91ba0',
      'd88353cb-4ec3-4477-b9dc-177692591b30',
      '1a81fd05-b61a-456c-8bed-9f1b90aef678',
      'e17214e1-28cb-4170-ab89-ea3532501251',
      '0226e0ff-c05f-43ed-8010-ed693312fa48',
      'fd24bb8d-fab2-4fa3-a762-db7c9ba798d1',
      '5d4d28c7-0a60-4b9f-ad58-3311be56d72c',
      '15f27418-b9f9-439e-8c33-1b014b9d2efe',
      '5302d781-2e01-4e11-adbb-1195308822e0',
      '97f5900a-e45d-4582-b71f-95e6d07a710b',
      'e2bd8feb-6862-4358-8976-c04cf6e387ba',
      'a0e92dda-3ba8-4c3a-9539-34e5a7071b4f',
      '3e7541f4-535a-42ad-b5d2-32bc46ce859e',
      // AU locations
      'c069c248-20b3-4446-8349-6b6197f92d8d',
      '3d2187d0-ba5f-4f82-b8da-577f67069534',
      // UAE location
      'bc4fc7bd-b27f-4c36-bc94-5a644ac6add7',
      // Dev locations
      '79cb14b5-5903-4d38-b0fd-b605b693bc22',
      '1163aebf-6c05-41b0-835e-77b4a59ba9b9',
      'bad58de6-8084-4861-bbcb-cba8b1e00298',
      '28f9feb1-b016-4325-bb71-8eff3301d59b',
      '640789e3-b3e0-4ad8-a7e4-8b24940e1d97',
    ],
  },
  {
    id: 2,
    title: 'Membership',
    description: '',
    image:
      'https://firebasestorage.googleapis.com/v0/b/i-mobile-app.appspot.com/o/FeatureCarousel%2FMembership.jpg?alt=media&token=c9d45f1a-a42f-4f8f-a5d0-03349b8e3d81',
    link: {
      US: 'https://fiveirongolf.com/membership/',
      UAE: 'https://fiveirongolf.com/membership/',
      AU: 'https://fiveirongolf.au/pages/membership',
    },
    locations: [
      // US locations
      '83d8c48c-a276-4258-b3ff-c904989de587',
      '8d050585-8094-4a66-a18a-df3e2edec2b9',
      'fbe1ce1b-8f48-430b-8015-da1c15f95f11',
      '027ecf74-3543-4243-9bf8-5aee1a64d2e5',
      'dd3ab333-b409-4879-b441-f28dd121f9ac',
      '60bcd77a-071f-4456-9f90-1f803d8780cb',
      '072c24bb-125e-4894-a155-616db85c9aec',
      '4388c520-a4de-4d49-b812-e2cb4badf667',
      '2626f492-d3f2-47be-ab2f-890d3775c963',
      '31f9eb4b-7fa7-4073-9c36-132b626c8b7e',
      'c71d765c-c7fd-4be7-aaba-2f3b21a91ba0',
      'd88353cb-4ec3-4477-b9dc-177692591b30',
      '1a81fd05-b61a-456c-8bed-9f1b90aef678',
      'e17214e1-28cb-4170-ab89-ea3532501251',
      '0226e0ff-c05f-43ed-8010-ed693312fa48',
      'fd24bb8d-fab2-4fa3-a762-db7c9ba798d1',
      '5d4d28c7-0a60-4b9f-ad58-3311be56d72c',
      '15f27418-b9f9-439e-8c33-1b014b9d2efe',
      '5302d781-2e01-4e11-adbb-1195308822e0',
      '97f5900a-e45d-4582-b71f-95e6d07a710b',
      'e2bd8feb-6862-4358-8976-c04cf6e387ba',
      'a0e92dda-3ba8-4c3a-9539-34e5a7071b4f',
      '3e7541f4-535a-42ad-b5d2-32bc46ce859e',
      // AU locations
      'c069c248-20b3-4446-8349-6b6197f92d8d',
      '3d2187d0-ba5f-4f82-b8da-577f67069534',
      // UAE location
      'bc4fc7bd-b27f-4c36-bc94-5a644ac6add7',
      // Dev locations
      '79cb14b5-5903-4d38-b0fd-b605b693bc22',
      '1163aebf-6c05-41b0-835e-77b4a59ba9b9',
      'bad58de6-8084-4861-bbcb-cba8b1e00298',
      '28f9feb1-b016-4325-bb71-8eff3301d59b',
      '640789e3-b3e0-4ad8-a7e4-8b24940e1d97',
    ],
  },
  {
    id: 3,
    title: 'Leagues',
    description: '',
    image:
      'https://firebasestorage.googleapis.com/v0/b/i-mobile-app.appspot.com/o/FeatureCarousel%2FLeagues.jpg?alt=media&token=daa263d5-4cee-499e-9650-ca32a39657d3',
    link: {
      US: 'https://fiveirongolf.com/leagues/',
      UAE: 'https://fiveirongolf.ae/pages/leagues',
      AU: 'https://fiveirongolf.au/pages/leagues',
    },
    locations: [
      // US locations
      '83d8c48c-a276-4258-b3ff-c904989de587',
      '8d050585-8094-4a66-a18a-df3e2edec2b9',
      'fbe1ce1b-8f48-430b-8015-da1c15f95f11',
      '027ecf74-3543-4243-9bf8-5aee1a64d2e5',
      'dd3ab333-b409-4879-b441-f28dd121f9ac',
      '60bcd77a-071f-4456-9f90-1f803d8780cb',
      '072c24bb-125e-4894-a155-616db85c9aec',
      '4388c520-a4de-4d49-b812-e2cb4badf667',
      '2626f492-d3f2-47be-ab2f-890d3775c963',
      '31f9eb4b-7fa7-4073-9c36-132b626c8b7e',
      'c71d765c-c7fd-4be7-aaba-2f3b21a91ba0',
      'd88353cb-4ec3-4477-b9dc-177692591b30',
      '1a81fd05-b61a-456c-8bed-9f1b90aef678',
      'e17214e1-28cb-4170-ab89-ea3532501251',
      '0226e0ff-c05f-43ed-8010-ed693312fa48',
      'fd24bb8d-fab2-4fa3-a762-db7c9ba798d1',
      '5d4d28c7-0a60-4b9f-ad58-3311be56d72c',
      '15f27418-b9f9-439e-8c33-1b014b9d2efe',
      '5302d781-2e01-4e11-adbb-1195308822e0',
      '97f5900a-e45d-4582-b71f-95e6d07a710b',
      'e2bd8feb-6862-4358-8976-c04cf6e387ba',
      'a0e92dda-3ba8-4c3a-9539-34e5a7071b4f',
      '3e7541f4-535a-42ad-b5d2-32bc46ce859e',
      // AU locations
      'c069c248-20b3-4446-8349-6b6197f92d8d',
      '3d2187d0-ba5f-4f82-b8da-577f67069534',
      // UAE location
      'bc4fc7bd-b27f-4c36-bc94-5a644ac6add7',
      // Dev locations
      '79cb14b5-5903-4d38-b0fd-b605b693bc22',
      '1163aebf-6c05-41b0-835e-77b4a59ba9b9',
      'bad58de6-8084-4861-bbcb-cba8b1e00298',
      '28f9feb1-b016-4325-bb71-8eff3301d59b',
      '640789e3-b3e0-4ad8-a7e4-8b24940e1d97',
    ],
  },
  {
    id: 4,
    title: 'Events',
    description:
      // eslint-disable-next-line max-len
      'Take a shot at a new way to play and party! From casual get-togethers with friends to full corporate buyouts, Five Iron offers flexible space, cutting-edge technology, a locally-inspired catering menu and beverage packages suited for every occasion. Our community-driven team is committed to helping you entertain, celebrate, and connect.\n*Five Iron Golf considers a reservation for a group larger than twelve to be an event.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/i-mobile-app.appspot.com/o/FeatureCarousel%2FEvents.jpg?alt=media&token=c63c8777-b571-415b-ab50-25fb06fc0280',
    link: {
      US: 'https://fiveirongolf.com/events/',
      UAE: 'https://www.fiveirongolf.ae/pages/contact-1',
      AU: 'https://fiveirongolf.au/pages/events',
    },
    locations: [
      // US locations
      '83d8c48c-a276-4258-b3ff-c904989de587',
      '8d050585-8094-4a66-a18a-df3e2edec2b9',
      'fbe1ce1b-8f48-430b-8015-da1c15f95f11',
      '027ecf74-3543-4243-9bf8-5aee1a64d2e5',
      'dd3ab333-b409-4879-b441-f28dd121f9ac',
      '60bcd77a-071f-4456-9f90-1f803d8780cb',
      '072c24bb-125e-4894-a155-616db85c9aec',
      '4388c520-a4de-4d49-b812-e2cb4badf667',
      '2626f492-d3f2-47be-ab2f-890d3775c963',
      '31f9eb4b-7fa7-4073-9c36-132b626c8b7e',
      'c71d765c-c7fd-4be7-aaba-2f3b21a91ba0',
      'd88353cb-4ec3-4477-b9dc-177692591b30',
      '1a81fd05-b61a-456c-8bed-9f1b90aef678',
      'e17214e1-28cb-4170-ab89-ea3532501251',
      '0226e0ff-c05f-43ed-8010-ed693312fa48',
      'fd24bb8d-fab2-4fa3-a762-db7c9ba798d1',
      '5d4d28c7-0a60-4b9f-ad58-3311be56d72c',
      '15f27418-b9f9-439e-8c33-1b014b9d2efe',
      '5302d781-2e01-4e11-adbb-1195308822e0',
      '97f5900a-e45d-4582-b71f-95e6d07a710b',
      'e2bd8feb-6862-4358-8976-c04cf6e387ba',
      'a0e92dda-3ba8-4c3a-9539-34e5a7071b4f',
      '3e7541f4-535a-42ad-b5d2-32bc46ce859e',
      // AU locations
      'c069c248-20b3-4446-8349-6b6197f92d8d',
      '3d2187d0-ba5f-4f82-b8da-577f67069534',
      // UAE location
      'bc4fc7bd-b27f-4c36-bc94-5a644ac6add7',
      // Dev locations
      '79cb14b5-5903-4d38-b0fd-b605b693bc22',
      '1163aebf-6c05-41b0-835e-77b4a59ba9b9',
      'bad58de6-8084-4861-bbcb-cba8b1e00298',
      '28f9feb1-b016-4325-bb71-8eff3301d59b',
      '640789e3-b3e0-4ad8-a7e4-8b24940e1d97',
    ],
  },
  {
    id: 5,
    title: 'Bowling',
    description: '',
    image:
      'https://firebasestorage.googleapis.com/v0/b/i-mobile-app.appspot.com/o/FeatureCarousel%2FDetroitDuckpin.jpg?alt=media&token=f98a39f2-81dc-4566-9fa5-27d264b5798d',
    link: {
      US: 'https://booking.detroitduckpin.com/select-location',
      UAE: '',
      AU: '',
    },
    locations: [
      // US locations
      '072c24bb-125e-4894-a155-616db85c9aec',
      // Dev locations
      '79cb14b5-5903-4d38-b0fd-b605b693bc22',
      '1163aebf-6c05-41b0-835e-77b4a59ba9b9',
      'bad58de6-8084-4861-bbcb-cba8b1e00298',
      '28f9feb1-b016-4325-bb71-8eff3301d59b',
    ],
  },
];
