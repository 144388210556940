import { AlertTitle, Link } from '@mui/material';
import React, { FC, useContext } from 'react';

import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import { getContactPageUrl } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/helpers';

import { StyledAlert } from './styles';

interface ErrorBoxProps {
  partySize: string;
  days: number;
}

const ErrorBox: FC<ErrorBoxProps> = ({ partySize, days }) => {
  const { getTextCopy } = useContext(TextCopyContext);

  const isSmallEvent = partySize === '7-12';
  const rawText = isSmallEvent ? getTextCopy('errorBoxSmallEvent') : getTextCopy('errorBox');
  const text = rawText.replace('{{ days }}', `${days}`);

  return (
    <StyledAlert severity="error">
      <AlertTitle style={{ color: 'inherit' }}>{text}</AlertTitle>
      If you are interested in booking an event please{' '}
      <strong>
        <Link
          href={getContactPageUrl()}
          target={'_blank'}
          style={{ textDecoration: 'none', color: 'inherit' }}>
          click here
        </Link>
      </strong>
    </StyledAlert>
  );
};

export default ErrorBox;
