import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AppointmentBooking } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { setBookingUUID, setEmail } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import { selectBookingForm } from 'infrastructure/redux/slices/bookingWizard.selector';
import { ISignInAdapter, SignInError } from 'application/modules/auth/useCases/hooks/useCaseSignIn';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
export type QueryOrSerializedError =
  | FetchBaseQueryError
  | (SerializedError & {
      status?: number;
    });

const getError = (error?: QueryOrSerializedError): SignInError | undefined => {
  if (!error) {
    return undefined;
  }

  if (error?.status === 404) {
    return SignInError.ClientDoesNotExist;
  }

  return SignInError.Unknown;
};

const useSignIn: ISignInAdapter = () => {
  const { navigateToAccountCreationPage } = useCaseExternalNavigation();
  const [loginAndSendEmail, { isLoading, error, isSuccess, data }] =
    mboAPI.useLogInWithEmailMutation();
  const [saveBookingData, { isLoading: isBookingSaving }] = mboAPI.useSaveBookingDataMutation();
  const bookingFormData = useAppSelector(selectBookingForm);
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);

  const handleSendVerificationLink = (email: string, locationId: string, bookingUUID?: string) => {
    dispatch(setEmail({ email }));
    loginAndSendEmail({ email, locationId, bookingUUID });
  };

  const handleSaveBookingData = async () => {
    const bookingData = {
      ...bookingFormData,
      lessonType: undefined,
      locationDetails: undefined,
    };
    const bookingUUID = await saveBookingData(bookingData as AppointmentBooking).unwrap();
    dispatch(setBookingUUID({ bookingUUID }));
    return bookingUUID;
  };

  const handleNavigateToCreateAccount = () => {
    navigateToAccountCreationPage();
  };

  return {
    inProgress: isLoading || isBookingSaving,
    result: data,
    isSuccess,
    error: getError(error),
    accessToken,
    bookingFormData,
    sendVerificationLink: handleSendVerificationLink,
    navigateToCreateAccount: handleNavigateToCreateAccount,
    handleSaveBookingData,
  };
};

export default useSignIn;
