import React, { FC } from 'react';
import { Box } from '@mui/material';

interface IFoodTabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
  [key: string]: unknown;
}

const FoodTabPanel: FC<IFoodTabPanel> = (props: IFoodTabPanel) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export default FoodTabPanel;
