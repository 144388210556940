import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSectionTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const StyledSectionTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const StyledIconContainer = styled(Box)(({ theme }) => ({
  minWidth: '32px',
  minHeight: '32px',
  height: '32px',
  width: '32px',
  marginRight: theme.spacing(4),
  borderRadius: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme?.palette?.secondary?.main,
  fill: theme?.palette?.secondary?.main,
  backgroundColor: theme?.palette?.secondary?.states.selected,
  svg: {
    height: '24px',
    width: '24px',
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: '46px',
    minHeight: '46px',
    height: '46px',
    width: '46px',
    svg: {
      height: '30px',
      width: '30px',
    },
  },
}));
