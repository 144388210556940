import React, { FC } from 'react';
import { Box, FormLabel, TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';

import { StyledPhoneInput } from './style';

interface IPhoneNumberInput {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  noFormLabel?: boolean;
  bottomText?: string;
  isConfirmed?: boolean;
  defaultCountry: string;
  type: 'text' | 'password' | 'email';
}

const PhoneNumberInput: FC<IPhoneNumberInput & TextFieldProps> = ({
  name,
  type,
  label,
  placeholder,
  isRequired,
  isDisabled,
  noFormLabel,
  bottomText,
  isConfirmed,
  defaultCountry,
  ...inputProps
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setFieldValue, errors, values, handleChange, handleBlur } = useFormikContext<any>();
  const value = values[name];
  const error = errors[name];

  React.useEffect(() => {
    if (isConfirmed) {
      setFieldValue('isConfirmed', isConfirmed);
    }
  }, [isConfirmed, setFieldValue]);

  const getHelperText = () => {
    if (error?.toString() && bottomText) {
      return `${bottomText} - ${error?.toString()}`;
    }
    if (!error?.toString() && bottomText) {
      return `${bottomText}`;
    }
    if (error?.toString() && !bottomText) {
      return `${error?.toString()}`;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {!noFormLabel && label && <FormLabel>{label}</FormLabel>}{' '}
      <StyledPhoneInput
        disableAreaCodes={true}
        defaultCountry={defaultCountry}
        inputClass="form-control"
        required={isRequired}
        disabled={isDisabled}
        type={type}
        data-testid={name}
        value={value}
        placeholder={placeholder}
        onBlur={handleBlur(name)}
        onChange={handleChange(name)}
        label={label}
        helperText={getHelperText()}
        enableLongNumbers={true}
        {...inputProps}
      />
    </Box>
  );
};

export default PhoneNumberInput;
