import { PaletteOptions } from '@mui/material/styles/createPalette';

export default {
  text: {
    primary: '#FFFFFF',
    secondary: '#E8E9F5',
  },
  primary: {
    main: '#5849FF',
    dark: '#282171',
    light: '#F73378',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#EEFF41',
    dark: '#0E908A',
    light: '#EEFF41',
    contrastText: '#282171',
    states: {
      selected: '#14CEC614',
    },
  },
  error: {
    main: '#F57E7E',
    dark: '#7C0404',
    light: '#F57E7E',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#0288D1',
    dark: '#01579B',
    light: '#03A9F4',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#00FFA3',
    dark: '#085A30',
    light: '#3C9A6A',
    contrastText: '#FFFFFF',
  },
  action: {
    active: '#0000008A',
    hover: '#0000000A',
    selected: '#00000014',
    focus: '#00000033',
    disabled: 'rgba(255, 255, 255, 0.26)',
    disabledBackground: '#0000001F',
  },
  other: {
    outlineBorder: 'rgba(232, 233, 245, 0.32)',
    divider: '#0000001F',
    overlay: '#00000080',
  },
  background: {
    default: '#080713',
    paper: '#080713',
    neutral: 'rgba(232,232,246,.16)',
  },
} as PaletteOptions;
