import { styled } from '@mui/system';
import { OutlinedInput, MenuItem, FormControl } from '@mui/material';

export const StyledBorderlessSelectInput = styled(OutlinedInput)(() => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

export const StyledMenuItemWithTransparentBg = styled(MenuItem)(() => ({
  '&.Mui-selected, &:hover': {
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginLeft: 'auto',
}));
