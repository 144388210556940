import { WizardStep } from 'domain/entities/WizardStep';
import { InjectableHook, useHookInjection } from 'domain/hooks';

export const BookingWizardNavigationHook = Symbol('BookingWizardNavigationHook');

export type IBookingWizardNavigationHook = InjectableHook<{
  redirectToNextStep(currentStep: WizardStep): void;
  redirectToPrevStep(currentStep: WizardStep): void;
  redirectToStep(step: WizardStep): void;
}>;

const useCaseNavigateBookingWizard = (currentStep: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );
  return {
    currentStep: currentStep,
    nextStep: () => {
      bookingWizardNavigation.redirectToNextStep(currentStep);
    },
    prevStep: () => {
      bookingWizardNavigation.redirectToPrevStep(currentStep);
    },
    moveToStep: (step: WizardStep) => {
      bookingWizardNavigation.redirectToStep(step);
    },
  };
};

export default useCaseNavigateBookingWizard;
