import * as React from 'react';
import { useFormikContext } from 'formik';
import { Box, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

export interface SelectOption {
  label: string;
  value: string;
}

interface FormikSelectProps {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  options: Array<SelectOption>;
  noFormLabel?: boolean;
}

const FormikSelect: React.FC<FormikSelectProps & SelectProps> = ({
  name,
  label,
  placeholder,
  options,
  isRequired,
  isDisabled,
  noFormLabel,
  ...inputProps
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, values, handleBlur, setFieldValue } = useFormikContext<any>();
  const value = values[name];
  const error = errors[name];

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }}>
      {!noFormLabel && label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
      <Select
        id={name}
        name={name}
        label={label}
        labelId={`${name}-label`}
        required={isRequired}
        placeholder={placeholder}
        disabled={isDisabled}
        data-testid={name}
        error={!!error}
        value={value}
        onBlur={handleBlur(name)}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        {...inputProps}>
        {options?.map((option) => (
          <MenuItem key={`${option.label}${option.value}}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.toString()}</FormHelperText>
    </Box>
  );
};

export default FormikSelect;
