import { InjectableHook, useHookInjection } from 'domain/hooks';

export const ExternalNavigationHook = Symbol('ExternalNavigationHook');

export type IExternalNavigationHook = InjectableHook<{
  openNewTabAndRedirectToContactPage(): void;
  redirectToCreateAccountPage(): void;
  openPageInANewTab(pageUrl: string): void;
  navigateToPage(pageSlug: string, state?: Record<string, unknown>): void;
  redirectToSignInPage(): void;
  redirectToCheckYourEmailPage(error?: unknown): void;
  redirectToMyBookingsPage(): void;
}>;

const useCaseExternalNavigation = () => {
  const externalNavigation = useHookInjection<IExternalNavigationHook>(ExternalNavigationHook);

  return {
    navigateToContactPage: externalNavigation.openNewTabAndRedirectToContactPage,
    navigateToAccountCreationPage: externalNavigation.redirectToCreateAccountPage,
    openPageInANewTab: externalNavigation.openPageInANewTab,
    navigateToPage: externalNavigation.navigateToPage,
    navigateToSignInPage: externalNavigation.redirectToSignInPage,
    navigateToCheckYourEmailPage: externalNavigation.redirectToCheckYourEmailPage,
    navigateToMyBookingsPage: externalNavigation.redirectToMyBookingsPage,
  };
};

export default useCaseExternalNavigation;
