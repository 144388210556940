import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { ICheckYourEmailAdapter } from 'application/modules/auth/useCases/hooks/useCaseCheckYourEmail';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
const useCheckYourEmail: ICheckYourEmailAdapter = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const { openPageInANewTab } = useCaseExternalNavigation();
  const [loginAndSendEmail, { isLoading, error, data }] = mboAPI.useLogInWithEmailMutation();

  const navigateToEmailProviderMailbox = (providerUrl: string, email?: string) => {
    openPageInANewTab(`${providerUrl}${email}`);
  };

  const handleResendVerificationLink = async (
    email: string,
    locationId: string,
    bookingUUID?: string,
  ) => {
    await loginAndSendEmail({ email, locationId, bookingUUID });
  };

  const handleNavigateToGenericMailbox = () => {
    window.location.assign('mailto:');
  };

  return {
    inProgress: isLoading,
    isLoggedIn: !!accessToken,
    result: data,
    error: error,
    navigateToEmailProviderMailbox,
    resendVerificationLink: handleResendVerificationLink,
    navigateToGenericMailbox: handleNavigateToGenericMailbox,
  };
};

export default useCheckYourEmail;
