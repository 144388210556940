import React, { FC } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { get } from 'lodash';
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';

import { StyledSelect } from './style';

export interface FormikSelectProps {
  name: string;
  label?: string;
  options: { value: string | number | undefined; label: string }[];
  onChange?: (value: unknown) => void;
  required?: boolean;
  sx?: Record<string, unknown>;
}

const FoodLineItemQuantitySelector: FC<FormikSelectProps> = (props) => {
  const { values } = useFormikContext();

  return (
    <Field name={props.name} required={props.required}>
      {({ field, form: { touched, errors, setFieldTouched } }: FieldProps) => {
        const hasError = !!get(errors, field.name) && !!get(touched, field.name);
        const value = get(values, props.name);

        const handleChange = (event: SelectChangeEvent<unknown>) => {
          setFieldTouched(field.name);
          const selectedValue = event.target.value;

          field.onChange({
            target: { value: selectedValue, name: props.name, type: 'select' },
          });

          if (props.onChange) {
            props.onChange(selectedValue);
          }
        };

        return (
          <FormControl fullWidth error={hasError} required={props.required}>
            {props.label && <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>}
            <StyledSelect
              labelId={`${props.name}-label`}
              {...field}
              value={value || ''}
              onChange={handleChange}
              data-testid={props.name}
              sx={{ ...props.sx }}>
              {props.options.map((option, idx) => (
                <MenuItem
                  key={`${idx}${option.value}`}
                  value={option.value}
                  sx={idx === 0 ? { color: 'error.main' } : undefined}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelect>
            {hasError && <FormHelperText>{get(errors, field.name)?.toString()}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FoodLineItemQuantitySelector;
