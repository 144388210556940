import React, { FC, useEffect } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { setClubRental, setLeftHanded } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch } from 'infrastructure/redux/store/hooks';
import { isAustralia } from 'infrastructure/targets/web/modules/common/helpers';

const QuestionnaireContainer: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setFieldValue } = useFormikContext<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLeftHanded({ leftHanded: false }));
    dispatch(setClubRental({ clubRental: false }));
    setFieldValue('leftHanded', false);
    setFieldValue('clubRental', false);
  }, []);

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }}>
      <Typography variant="h5">Any Lefties?</Typography>

      <Typography variant="body2" color={'text.secondary'}>
        Let us know if there are any left handed golfers in your party.
      </Typography>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue="No">
        <FormControlLabel
          value="Yes"
          control={<Radio color="secondary" size="medium" />}
          label="Yes"
          onClick={() => {
            dispatch(setLeftHanded({ leftHanded: true }));
            setFieldValue('leftHanded', true);
          }}
        />
        <FormControlLabel
          value="No"
          control={<Radio color="secondary" size="medium" />}
          label="No"
          sx={{ ml: '10px' }}
          onClick={() => {
            dispatch(setLeftHanded({ leftHanded: false }));
            setFieldValue('leftHanded', false);
          }}
        />
      </RadioGroup>
      <Typography variant="h5" sx={{ mt: '15px' }}>
        Will You Need Clubs?
      </Typography>
      <Typography variant="body2" color={'text.secondary'}>
        {isAustralia()
          ? 'Additional charge will be applied for any clubs requested.'
          : 'Clubs are provided free of charge.'}
      </Typography>

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue="No">
        <FormControlLabel
          value="Yes"
          control={<Radio color="secondary" size="medium" />}
          label="Yes"
          onClick={() => {
            dispatch(setClubRental({ clubRental: true }));
            setFieldValue('clubRental', true);
          }}
        />
        <FormControlLabel
          value="No"
          control={<Radio color="secondary" size="medium" />}
          label="No"
          sx={{ ml: '10px' }}
          onClick={() => {
            dispatch(setClubRental({ clubRental: false }));
            setFieldValue('clubRental', false);
          }}
        />
      </RadioGroup>
    </Box>
  );
};

export default QuestionnaireContainer;
