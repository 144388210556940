import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { add, isSameMonth, sub } from 'date-fns';

import { getAdapterLocale } from 'infrastructure/targets/web/modules/common/helpers';

import { handleDisabledDates } from './helpers';
import { StyledCalendarContainer, StyledLeftDatePicker, StyledRighDatePicker } from './style';

export interface IBookingCalendar {
  onChange?: (newDate: Date | null) => void;
  onMonthChange?: (newDate: Date | null) => void;
  availableDates?: Array<Date>;
  selectedValue?: string;
}

const BookingCalendar: FC<IBookingCalendar> = ({ onChange, availableDates, onMonthChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const datePickerMode = isMobile ? 'mobile' : 'desktop';

  const dateTimeout = useRef<number>();
  const leftTimeout = useRef<number>();
  const rightTimeout = useRef<number>();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [leftPickerValue, setLeftPickerValue] = useState<Date | null>(new Date());
  const [rightPickerValue, setRightPickerValue] = useState<Date | null>(null);

  const [leftPickerDefaultMonth, setLeftPickerDefaultMonth] = useState<Date>(new Date());
  const [rightPickerDefaultMonth, setRightPickerDefaultMonth] = useState<Date>(
    add(new Date(), { months: 1 }),
  );

  const handleDateChange = (newValue: Date | null, picker: number) => {
    setSelectedDate(newValue);
    if (onChange) {
      onChange(newValue);
    }
    switch (picker) {
      case 1:
        setLeftPickerValue(newValue);
        setRightPickerValue(null);
        break;
      case 2:
        setLeftPickerValue(null);
        setRightPickerValue(newValue);
        break;
      default:
        break;
    }
  };

  const setPreviouslySetDateForCorrespondingPicker = (month: Date, picker: number) => {
    dateTimeout.current = window.setTimeout(() => {
      if (selectedDate) {
        if (
          (leftPickerValue &&
            isSameMonth(month, selectedDate) &&
            isSameMonth(selectedDate, leftPickerValue)) ||
          (!leftPickerValue &&
            !rightPickerValue &&
            isSameMonth(month, selectedDate) &&
            picker === 1)
        ) {
          setLeftPickerValue(selectedDate);
        }
        if (
          (rightPickerValue &&
            isSameMonth(month, selectedDate) &&
            isSameMonth(selectedDate, rightPickerValue)) ||
          (!leftPickerValue &&
            !rightPickerValue &&
            isSameMonth(month, selectedDate) &&
            picker === 2)
        ) {
          setRightPickerValue(selectedDate);
        }
      }
    }, 0);
  };

  const handleMonthChange = (month: Date, picker: number) => {
    switch (picker) {
      case 1:
        setLeftPickerDefaultMonth(month);
        setRightPickerDefaultMonth(add(month, { months: 1 }));
        setLeftPickerValue(null);
        setRightPickerValue(add(month, { months: 1 }));
        leftTimeout.current = window.setTimeout(() => {
          setRightPickerValue(null);
          setPreviouslySetDateForCorrespondingPicker(month, picker);
        }, 0);

        break;
      case 2:
        setLeftPickerDefaultMonth(sub(month, { months: 1 }));
        setRightPickerDefaultMonth(month);
        setRightPickerValue(null);
        setLeftPickerValue(sub(month, { months: 1 }));
        rightTimeout.current = window.setTimeout(() => {
          setLeftPickerValue(null);
          setPreviouslySetDateForCorrespondingPicker(month, picker);
        }, 0);
        if (onMonthChange) {
          onMonthChange(month);
        }

        break;
      default:
        break;
    }
  };

  useEffect(
    () => () => {
      clearTimeout(dateTimeout as unknown as number);
      clearTimeout(leftTimeout as unknown as number);
      clearTimeout(rightTimeout as unknown as number);
    },
    [],
  );

  const DisabledArrowButton = () => <Box sx={{ display: 'none' }} />;

  const leftPickerComponents = !isMobile
    ? {
        RightArrowButton: DisabledArrowButton,
      }
    : {};

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getAdapterLocale()}>
      <StyledCalendarContainer>
        <StyledLeftDatePicker
          components={leftPickerComponents}
          displayStaticWrapperAs={datePickerMode}
          value={leftPickerValue}
          showToolbar={false}
          shouldDisableDate={(day) => handleDisabledDates(day, availableDates)}
          views={['day']}
          disablePast
          onMonthChange={(month) => handleMonthChange(month as Date, 1)}
          onChange={(newValue) => handleDateChange(newValue as Date, 1)}
          defaultCalendarMonth={leftPickerDefaultMonth}
          renderInput={(params) => <TextField {...params} />}
        />
        {!isMobile && (
          <StyledRighDatePicker
            components={{
              LeftArrowButton: DisabledArrowButton,
            }}
            displayStaticWrapperAs={datePickerMode}
            showToolbar={false}
            disablePast
            shouldDisableDate={(day) => handleDisabledDates(day, availableDates)}
            value={rightPickerValue}
            onMonthChange={(month) => handleMonthChange(month as Date, 2)}
            views={['day']}
            onChange={(newValue) => handleDateChange(newValue as Date, 2)}
            defaultCalendarMonth={rightPickerDefaultMonth}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </StyledCalendarContainer>
    </LocalizationProvider>
  );
};

export default BookingCalendar;
