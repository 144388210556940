import { Children, FC, isValidElement, ReactNode } from 'react';

import { WizardStep } from 'domain/entities/WizardStep';

interface IBookingWizardContainer {
  children?: ReactNode;
  currentStep: WizardStep;
}
const BookingWizardContainer: FC<IBookingWizardContainer> = ({ children, currentStep }) => {
  const filteredChildren = Children.toArray(children).filter(Boolean);

  const matchStep = (stepName: Array<string> | string) => {
    if (Array.isArray(stepName)) {
      return stepName.includes(currentStep);
    }
    return stepName === currentStep;
  };

  return (
    <>
      {Children.map(filteredChildren, (child) => {
        if (isValidElement(child)) {
          return matchStep(child.props.stepName) ? child : null;
        }
      })}
    </>
  );
};

export default BookingWizardContainer;
