import React, { PropsWithChildren, FC } from 'react';
import { Variants } from 'common';

import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import TextCopy from 'infrastructure/targets/web/assets/text/text';

export const TextCopyContextProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const provider = {
    getTextCopy: (code: string) =>
      TextCopy[(process.env.VITE_VARIANT || Variants.FIVEIRON) as Variants][code],
  };

  return <TextCopyContext.Provider value={provider}>{children}</TextCopyContext.Provider>;
};
