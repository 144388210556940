import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSectionColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '640px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const StyledSummaryDataRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(2),
  justifyContent: 'space-between',
  width: '100%',
}));
