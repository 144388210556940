import { useInjection } from 'inversify-react';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';

export interface PreOrderFoodFormValues {
  order: Record<string, FoodLineItemValue | undefined>;
  notes: string;
}
export interface FoodLineItemValue {
  name: string;
  title: string;
  productId: string;
  variantId: string;
  quantity: number;
  subtitle?: string;
  price?: number;
  imageUrl?: string;
}

export type IPreOrderFoodHook = InjectableHook<{
  handleSavePreOrderFoodData: (values: PreOrderFoodFormValues) => void;
}>;

export const PreOrderFoodAdapter = Symbol('PreOrderFoodAdapter');
export type IPreOrderFoodAdapter = InjectableHook<
  AsyncHookResult<void, unknown> & {
    handleSavePreOrderFoodData: (values: PreOrderFoodFormValues) => void;
    isSuccess: boolean;
  }
>;

const useCasePreOrderFood = () => {
  const adapter = useInjection<IPreOrderFoodAdapter>(PreOrderFoodAdapter);
  const { error, inProgress, isSuccess, handleSavePreOrderFoodData } = adapter();

  return {
    inProgress,
    isSuccess,
    error,
    handleSavePreOrderFoodData,
  };
};

export default useCasePreOrderFood;
