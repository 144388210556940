import { styled } from '@mui/system';

import { ExtendedToggleButtonGroup } from '.';

export const StyledToggleButtonGroup = styled(ExtendedToggleButtonGroup)(
  ({ theme, numberofbuttons, shouldstayinonerow }) => ({
    display: 'grid',
    width: '100%',
    gap: theme.spacing(2),
    gridTemplateColumns: `repeat(${numberofbuttons}, 1fr)`,
    gridTemplateRows: 'repeat(1, 1fr)',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [`${!shouldstayinonerow && theme.breakpoints.up('sm')}`]: {
      gridTemplateColumns: numberofbuttons < 4 ? `repeat(3, auto)` : `repeat(4, auto)`,
      gridTemplateRows: `repeat(${numberofbuttons / 4}, 1fr)`,
    },
  }),
);
