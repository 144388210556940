import { injectable } from 'inversify';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { WizardStep } from 'domain/entities/WizardStep';
import IWebModule from 'infrastructure/targets/web/types/IWebModule';

import { AccountRoutes, BookingWizardRoutes } from './navigation/BookingWizardRoutes';
import { ManageBookingRoutes } from './navigation/ManageBookingRoutes';
import BookingWizard from './views/BookingWizard.view';
import ManageBookingsView from './views/ManageBookings.view';
import Verification from './views/Verification.view';

@injectable()
class BookingWizardModule implements IWebModule {
  getRoutes(): JSX.Element {
    return (
      <>
        <Route path="*" element={<Navigate to={WizardStep.selectLocation} replace />} />
        <Route
          path={BookingWizardRoutes.BookingWizard}
          key={'bookingWizard'}
          element={<BookingWizard />}
        />
        <Route path={AccountRoutes.VerifyUser} key={'verify'} element={<Verification />} />
        <Route
          path={ManageBookingRoutes.Index}
          key={'manageBookings'}
          element={<ManageBookingsView />}
        />
      </>
    );
  }
}

export default BookingWizardModule;
