import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledAsideContainer = styled('aside')(({ theme }) => ({
  flex: '1 1 auto',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(12),
  },
}));

export const StyledLeftContainer = styled(Box)(({ theme }) => ({
  flex: '0 0 auto',
  maxWidth: '850px',
  minWidth: '800px',
  paddingRight: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    minWidth: '100%',
    paddingRight: theme.spacing(0),
  },
}));

export const StyledInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'row',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledButtonsContainer = styled(Box)(({ theme }) => ({
  paddingLeft: '62px',
  display: 'flex',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(7),
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(0),
  },
}));

export const StyledLoaderContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
}));

export const StyledCalendarContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}));
