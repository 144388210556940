import { useEffect, useRef } from 'react';

const isDevelopmentRuntime = () => {
  try {
    return import.meta.env.DEV && import.meta.env.MODE !== 'test';
  } catch (error) {
    return true;
  }
};

const useEffectOnce = (cb: () => void, deps: Array<unknown>) => {
  const isMountedRef = useRef(!isDevelopmentRuntime());

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return undefined;
    }

    return cb();
  }, deps);
};

export default useEffectOnce;
