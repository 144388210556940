import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledContentCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.other.outlineBorder}`,
  borderRadius: '8px',
  padding: theme.spacing(6, 5, 6, 5),
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
    border: 'none',
    padding: theme.spacing(0),
  },
}));
