import { useEffect } from 'react';

const WidgetComponent = () => {
  useEffect(() => {
    const WIDGET_TEXT = 'Noteefy Tee Time Waitlist';
    const IFRAME_SOURCE = 'https://fiveirongolfnyc.noteefy.app';
    const WIDGET_THEME_SOURCE =
      'https://noteefypublic.blob.core.windows.net/widget/NYFiveIron/NYFiveIronWidget.css';

    const noteefyWidget = document.createElement('div');
    noteefyWidget.id = 'noteefy-widget';
    noteefyWidget.className = 'widget-closed';
    document.body.appendChild(noteefyWidget);

    const noteefyWidgetHeader = document.createElement('div');
    noteefyWidgetHeader.id = 'noteefy-widget-header';
    noteefyWidget.appendChild(noteefyWidgetHeader);

    const styleSheet = document.createElement('link');
    styleSheet.href = WIDGET_THEME_SOURCE;
    styleSheet.rel = 'stylesheet';
    noteefyWidget.appendChild(styleSheet);

    const widgetIcon = document.createElement('div');
    widgetIcon.id = 'noteefy-icon';
    const widgetText = document.createElement('span');
    widgetText.innerText = WIDGET_TEXT;
    const widgetArrow = document.createElement('div');
    widgetArrow.id = 'noteefy-arrow';
    widgetArrow.className = 'widget-closed';
    noteefyWidgetHeader.appendChild(widgetIcon);
    noteefyWidgetHeader.appendChild(widgetText);
    noteefyWidgetHeader.appendChild(widgetArrow);

    const widgetBody = document.createElement('div');
    widgetBody.id = 'noteefy-widget-body';
    const widgetIFrame = document.createElement('iframe');
    widgetIFrame.id = 'noteefy-widget-iframe';
    widgetIFrame.src = IFRAME_SOURCE;
    widgetIFrame.scrolling = 'auto';
    widgetIFrame.width = '100%';
    widgetIFrame.height = '100%';
    widgetBody.appendChild(widgetIFrame);
    noteefyWidget.appendChild(widgetBody);

    // Add click listeners
    noteefyWidgetHeader.addEventListener('click', toggleOpen);

    return () => {
      document.body.removeChild(noteefyWidget);
    };
  }, []);

  const toggleOpen = () => {
    const widgetClassName = document.getElementById('noteefy-widget')?.className;

    if (widgetClassName === 'widget-closed') {
      document.getElementById('noteefy-widget').className = 'widget-open';
      document.getElementById('noteefy-arrow').className = 'widget-open';
    } else {
      document.getElementById('noteefy-widget').className = 'widget-closed';
      document.getElementById('noteefy-arrow').className = 'widget-closed';
    }
  };

  return null; // Render nothing since the widget is appended to the document body
};

export default WidgetComponent;
