import { createSlice } from '@reduxjs/toolkit';

export interface ThemeState {
  isDarkTheme?: boolean;
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    isDarkTheme: false,
  } as ThemeState,
  reducers: {
    toggleColorMode: (state: ThemeState, { payload }) => ({
      ...state,
      isDarkTheme: payload.isDarkTheme,
    }),
  },
});

export const { toggleColorMode } = themeSlice.actions;
export default themeSlice.reducer;
