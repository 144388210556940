import { Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { StyledContentCardContainer } from './style';

interface IContentCard {
  children?: ReactNode;
  title?: ReactNode;
  sx?: Record<string, unknown>;
}

const ContentCard: FC<IContentCard> = ({ children, title, sx }) => (
  <StyledContentCardContainer sx={{ ...sx }}>
    {title && <Typography variant="h2">{title}</Typography>}
    {children}
  </StyledContentCardContainer>
);

export default ContentCard;
