import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { IMaskInput } from 'react-imask';
interface FormikInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  noFormLabel?: boolean;
  inputMask?: string;
  bottomText?: string;
  applyButton?: boolean;
  isConfirmed?: boolean;
  inProgress?: boolean;
  handleApplyPromo?: (val: string) => void;
  type: 'text' | 'password' | 'email';
}

interface InputMaskProps {
  onChange: (event: { target: { name: string; value: unknown } }) => void;
  name: string;
  inputMask: string;
}

const InputWithMask = React.forwardRef<HTMLElement, InputMaskProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={props.inputMask}
      definitions={{
        '#': /[1-9]/,
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputRef={ref as any}
      onAccept={(value: unknown) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const FormikInput: React.FC<FormikInputProps & TextFieldProps> = ({
  name,
  type,
  label,
  placeholder,
  isRequired,
  isDisabled,
  applyButton,
  handleApplyPromo,
  noFormLabel,
  inputMask,
  bottomText,
  isConfirmed,
  inProgress,
  ...inputProps
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setFieldValue, errors, values, handleChange, handleBlur } = useFormikContext<any>();
  const value = values[name];
  const error = errors[name];

  React.useEffect(() => {
    if (isConfirmed) {
      setFieldValue('isConfirmed', isConfirmed);
    }
  }, [isConfirmed, setFieldValue]);

  const getHelperText = () => {
    if (error?.toString() && bottomText) {
      return `${bottomText} - ${error?.toString()}`;
    }
    if (!error?.toString() && bottomText) {
      return `${bottomText}`;
    }
    if (error?.toString() && !bottomText) {
      return `${error?.toString()}`;
    }
  };
  const inputForMaskedInput = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputComponent: InputWithMask as any,
    inputProps: { inputMask },
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const ApplyButton = () => (
    <InputAdornment position="end">
      <IconButton
        disabled={isDisabled ? isDisabled : false}
        onClick={() => (handleApplyPromo ? handleApplyPromo(value) : null)}
        onMouseDown={handleMouseDown}
        sx={{
          ml: 1,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}>
        {inProgress ? (
          <CircularProgress sx={{ color: 'text.primary' }} />
        ) : (
          <Typography variant="subtitle1" color="text.primary" fontWeight={650}>
            Apply
          </Typography>
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Box sx={{ width: '100%' }}>
      {!noFormLabel && label && <FormLabel>{label}</FormLabel>}
      <TextField
        required={isRequired}
        disabled={isDisabled}
        type={type}
        data-testid={name}
        value={value}
        InputProps={
          applyButton
            ? { endAdornment: <ApplyButton /> }
            : inputMask
            ? inputForMaskedInput
            : undefined
        }
        placeholder={placeholder}
        onBlur={handleBlur(name)}
        onChange={handleChange(name)}
        label={label}
        helperText={getHelperText()}
        {...inputProps}
      />
    </Box>
  );
};

export default FormikInput;
