import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';
import { BookingForm } from 'infrastructure/redux/slices/bookingWizard.slice';

export enum SignInError {
  InvalidEmail = 'invalid_email',
  Unknown = 'unknown',
  ClientDoesNotExist = 'client_does_not_exist',
}

export const SignInAdapter = Symbol('SignInAdapter');
export type ISignInAdapter = InjectableHook<
  AsyncHookResult<unknown, SignInError> & {
    sendVerificationLink: (email: string, locationId: string, bookingUUID?: string) => void;
    navigateToCreateAccount: () => void;
    navigateToLogin: () => void;
    resetToWelcomeScreen: () => void;
    handleSaveBookingData: () => Promise<string>;
    accessToken: string;
    isSuccess: boolean;
    bookingFormData: BookingForm;
  }
>;

const useCaseSignIn = (currentStep: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );
  const [isLoading, setIsLoading] = useState(false);

  const adapter = useInjection<ISignInAdapter>(SignInAdapter);
  const {
    error,
    result,
    isSuccess,
    inProgress,
    sendVerificationLink,
    navigateToCreateAccount,
    navigateToLogin,
    resetToWelcomeScreen,
    bookingFormData,
    handleSaveBookingData,
  } = adapter();

  const handleNextStep = async (email: string, locationId: string) => {
    setIsLoading(true);
    try {
      const bookingUUID = await handleSaveBookingData();
      if (bookingUUID) {
        sendVerificationLink(email, locationId, bookingUUID);
      } else {
        sendVerificationLink(email, locationId);
      }
    } catch (e) {
      sendVerificationLink(email, locationId);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      bookingWizardNavigation.redirectToNextStep(currentStep);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!bookingFormData?.location) {
      bookingWizardNavigation.redirectToStep(WizardStep.selectLocation);
    }
    if (bookingFormData?.location && !bookingFormData?.experienceType) {
      bookingWizardNavigation.redirectToStep(WizardStep.selectExperience);
    }
    if (
      (bookingFormData?.experienceType === BookingWizardFlowTypes.Simulator ||
        bookingFormData?.experienceType === BookingWizardFlowTypes.Bowling) &&
      !bookingFormData?.reservationDate
    ) {
      bookingWizardNavigation.redirectToStep(WizardStep.reservationDate);
    }
    if (
      bookingFormData?.experienceType === BookingWizardFlowTypes.Lesson &&
      !bookingFormData?.instructor
    ) {
      bookingWizardNavigation.redirectToStep(WizardStep.selectInstructor);
    }
    if (
      (bookingFormData?.experienceType === BookingWizardFlowTypes.Simulator ||
        bookingFormData?.experienceType === BookingWizardFlowTypes.Bowling) &&
      !bookingFormData?.appointments
    ) {
      bookingWizardNavigation.redirectToStep(WizardStep.sessionLength);
    }
  }, [bookingFormData]);

  return {
    inProgress: inProgress || isLoading,
    error,
    result,
    navigateToCreateAccount,
    navigateToLogin,
    resetToWelcomeScreen,
    nextStep: handleNextStep,
    prevStep: () => {
      bookingWizardNavigation.redirectToPrevStep(currentStep);
    },
  };
};

export default useCaseSignIn;
