import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';

import ServiceIdentifier = interfaces.ServiceIdentifier;

export type AsyncHookResult<R = unknown, E = unknown> = {
  result?: R;
  inProgress: boolean;
  error?: E;
};
export type InjectableHook<T extends object> = () => T;

export function useHookInjection<T extends InjectableHook<any>>(
  identifier: ServiceIdentifier<T>,
): ReturnType<T> {
  return useInjection<T>(identifier)();
}
