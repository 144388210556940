import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { sendPageView, sendEvent } from 'infrastructure/targets/web/common/tracking';
import { InjectableHook } from 'domain/hooks';

export type IEventTrackingHook = InjectableHook<{
  sendPageView(): void;
  sendEvent(eventName: string, eventDetails?: any, items?: any[]): void;
}>;

export enum EventType {
  SignUp = 'sign_up',
  Login = 'login',
  SelectReservationDate = 'select_reservation_date',
  SelectExperience = 'select_experience',
  SelectSessionLength = 'select_session_length',
  BeginCheckout = 'begin_checkout',
  AddShippingInfo = 'add_shipping_info',
}

const useEventTracking: IEventTrackingHook = () => {
  const location = useLocation();
  const user = useAppSelector(selectUserSummary);

  return {
    sendPageView: () => sendPageView(location.pathname, user),
    sendEvent: (eventName: string, eventDetails?: any, items?: any[]) =>
      sendEvent(eventName, location.pathname, user, eventDetails, items),
  };
};

export default useEventTracking;
