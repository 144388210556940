import { UserSummary } from 'common/infrastructure/modules/auth/interfaces/UserTypes';
import { v4 as uuidv4 } from 'uuid';

import { WizardStep } from 'domain/entities/WizardStep';

const RouteContentGroupMapping: Record<string, string> = {
  '/select-location': WizardStep.selectLocation,
  '/select-experience': WizardStep.selectExperience,
  '/reservation-date': WizardStep.reservationDate,
  '/session-length': WizardStep.sessionLength,
  '/select-instructor': WizardStep.selectInstructor,
  '/choose-lesson-time': WizardStep.chooseLessonTime,
  '/create-account': 'Account',
  '/sign-in': 'Account',
  '/check-your-email': 'Account',
  '/verify': 'Account',
  '/payment-details': 'Checkout',
  '/confirm-reservation': 'Checkout',
  '/see-you-soon': WizardStep.seeYouSoon,
  '/pricing': WizardStep.pricing,
  '/pre-order-food': WizardStep.preOrderFood,
  '/manage-bookings': 'manage-bookings',
};

export interface UserData {
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  phone_number: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  zip_code: string | null;
}

export interface ConversionUserData {
  log_state: 'Logged In' | 'Logged Out';
  user_id: string | null;
  user_hashed_data: UserData;
  enhanced_conversion_data: UserData;
}

const hashFields = async (obj: Record<string, any>): Promise<Record<string, any>> => {
  const encoder = new TextEncoder();
  const result: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (!value) {
      result[key] = null;
    }
    const encodedValue = encoder.encode(String(value));
    const hashBuffer = await crypto.subtle.digest('SHA-256', encodedValue);
    const hashHex = Array.from(new Uint8Array(hashBuffer))
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
    result[key] = hashHex;
  }

  return result;
};

const prepareUserConversionData = async (summary?: UserSummary | null) => {
  const user: UserData = {
    email: summary?.email || null,
    first_name: summary?.firstName || null,
    last_name: summary?.lastName || null,
    phone_number: summary?.phone || null,
    street: null,
    city: null,
    state: null,
    country: null,
    zip_code: summary?.postalCode || null,
  };
  return {
    log_state: summary ? 'Logged In' : 'Logged Out',
    user_id: summary?.clientId || null,
    user_hashed_data: await hashFields(user),
    enhanced_conversion_data: user,
  };
};

const preparePayload = async (
  eventName: string,
  path: string,
  user?: UserSummary | null,
  eventDetails?: any,
  items?: any[],
) => {
  const id = uuidv4();
  const contentGroup = RouteContentGroupMapping[path] || path;
  return {
    event: eventName,
    ecommerce: {
      event_details: {
        event_id: id,
        content_group: contentGroup,
        ...eventDetails,
      },
      user_details: await prepareUserConversionData(user),
      items,
    },
  };
};

export const sendPageView = async (path: string, user?: UserSummary | null) => {
  const payload = await preparePayload('page_view', path, user);
  dataLayer?.push(payload);
};

export const sendEvent = async (
  eventName: string,
  path: string,
  user?: UserSummary | null,
  eventDetails?: Record<string, unknown>,
  items?: any[],
) => {
  const payload = await preparePayload(eventName, path, user, eventDetails, items);
  dataLayer?.push(payload);
};
