import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';

export const StyledTextInput = styled(TextField)(({ theme }) => ({
  input: {
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,

    width: '40px',
  },
  '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderStyle: 'none',
  },
  '.MuiOutlinedInput-root .MuiOutlinedInput-input': {
    padding: '4.5px 14px',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  alignSelf: 'center',
  '&.MuiButton-sizeLarge': {
    padding: theme.spacing(3),
  },
  '&.MuiButton-sizeMedium': {
    padding: theme.spacing(2),
  },
  '&.MuiButton-sizeSmall': {
    padding: theme.spacing(1),
  },
}));
