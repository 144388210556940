import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AuthDto } from 'common';
import { AppointmentBooking } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { setEmail } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectBookingForm } from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  CreateAccountError,
  ICreateAccountAdapter,
} from 'application/modules/auth/useCases/hooks/useCaseCreateAccount';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
type QueryOrSerializedError =
  | FetchBaseQueryError
  | (SerializedError & {
      status?: number;
    });

const getError = (error?: QueryOrSerializedError): CreateAccountError | undefined => {
  if (!error) {
    return undefined;
  }

  if (error?.status === 409) {
    return CreateAccountError.ClientAlreadyExists;
  }

  if (error?.status === 400) {
    return CreateAccountError.Error;
  }

  return CreateAccountError.Unknown;
};

const useCreateAccount: ICreateAccountAdapter = () => {
  const { navigateToCheckYourEmailPage, navigateToSignInPage } = useCaseExternalNavigation();
  const [createAccount, { isLoading, error, data, isSuccess }] = mboAPI.useCreateAccountMutation();
  const [saveBookingData] = mboAPI.useSaveBookingDataMutation();
  const bookingFormData = useAppSelector(selectBookingForm);
  const dispatch = useAppDispatch();

  const handleSaveBookingData = async () => {
    const bookingData = {
      ...bookingFormData,
      lessonType: undefined,
      locationDetails: undefined,
    };
    const bookingUUID = saveBookingData(bookingData as AppointmentBooking).unwrap();
    return bookingUUID;
  };

  const handleCreateAccount = (values: AuthDto.RegisterDto, bookingUUID?: string) => {
    dispatch(setEmail({ email: values.email }));
    createAccount({ registerValues: values, bookingUUID });
  };

  const handleNavigateToCheckYourEmail = () => {
    navigateToCheckYourEmailPage();
  };

  const handleNavigateToSignIn = () => {
    navigateToSignInPage();
  };

  return {
    inProgress: isLoading,
    result: data,
    isSuccess,
    error: getError(error),
    bookingFormData,
    sendVerificationLink: handleCreateAccount,
    handleNavigateToCheckYourEmail: handleNavigateToCheckYourEmail,
    handleNavigateToSignIn: handleNavigateToSignIn,
    handleSaveBookingData,
  };
};

export default useCreateAccount;
