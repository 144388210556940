import * as Sentry from '@sentry/browser';
import { UserTypes } from 'common';
import { useInjection } from 'inversify-react';
import { useEffect } from 'react';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';
import { BookingForm } from 'infrastructure/redux/slices/bookingWizard.slice';

export enum UserVerificationError {
  Unknown = 'unknown',
  Unauthorized = 'unauthorized',
  ValidationError = 'email_validation_error',
}

export interface LoginWithVerificationTokenOutputBoundary {
  accessToken: string;
  redirectTo: string;
  refreshToken: string;
}

export const VerifyUserAdapter = Symbol('VerifyUserAdapter');

export type IVerifyUserAdapter = InjectableHook<
  AsyncHookResult<LoginWithVerificationTokenOutputBoundary, UserVerificationError> & {
    verifyUser: (
      token: string | null,
      email: string | null,
      bookingData?: string | null,
      useMobile?: boolean,
    ) => void;
    handleAccessToken: () => void;
    redirectToCheckYourEmail: (error: UserVerificationError) => void;
    redirectToConfirmReservation: () => void;
    handleSaveUserDataToStore: (userSummary: UserTypes.UserSummary | undefined) => void;
    handleGetUser: () => Promise<UserTypes.UserSummary> | undefined;
    isUserDataLoading: boolean;
    isAlreadyLoggedIn: boolean;
    redirectToPaymentDetails: () => void;
    redirectToSelectExperience: () => void;
    redirectToLocationSelect: () => void;
    logoutSignedInUser: () => void;
    bookingFormData: BookingForm;
  }
>;

const useCaseVerifyUser = () => {
  const adapter = useInjection<IVerifyUserAdapter>(VerifyUserAdapter);
  const {
    error,
    result,
    inProgress,
    verifyUser,
    handleAccessToken,
    redirectToCheckYourEmail,
    redirectToConfirmReservation,
    handleSaveUserDataToStore,
    redirectToPaymentDetails,
    redirectToSelectExperience,
    handleGetUser,
    isAlreadyLoggedIn,
    logoutSignedInUser,
    bookingFormData,
  } = adapter();

  const handleVerifyUser = (
    token: string | null,
    email: string | null,
    bookingUUID: string | null,
    useMobile?: boolean,
  ) => {
    if (isAlreadyLoggedIn) {
      logoutSignedInUser();
      verifyUser(token, email, bookingUUID, useMobile);
    } else {
      verifyUser(token, email, bookingUUID, useMobile);
    }
  };

  useEffect(() => {
    if (!result && error) {
      redirectToCheckYourEmail(error);
    }
    if (result?.accessToken) {
      handleAccessToken();
      const fetchUserData = async () => {
        const payload = await handleGetUser();
        if (!payload?.email) {
          Sentry.addBreadcrumb({
            category: 'auth',
            message: 'Verify user confirm' + JSON.stringify(payload),
            level: 'error',
          });
        }

        handleSaveUserDataToStore(payload);
        if (!payload?.creditCard) {
          redirectToPaymentDetails();
          return;
        }
        if (bookingFormData?.appointments) {
          redirectToConfirmReservation();
          return;
        }
        redirectToSelectExperience();
      };
      fetchUserData();
    }
  }, [result, error, bookingFormData]);

  return {
    inProgress,
    error,
    result,
    verifyUser: handleVerifyUser,
  };
};

export default useCaseVerifyUser;
