import i18n, { ResourceLanguage, TOptions } from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

import 'intl-pluralrules';

import en from './languages/en.json';

const resources = {
  en: {
    translation: en as ResourceLanguage,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((err) => {
    console.warn('Failed to initialize i18next', err);
  });

const currencyOverrides: Record<string, string> = {
  AUD: 'USD', // Show AUD as "$0.00" instead of "A$0.00"
};

i18n.services.formatter?.add('currency', (value, lng, { currency, ...options }) => {
  const currencyOverride = typeof currency === 'string' ? currencyOverrides[currency] : undefined;
  const minimumFractionDigits = Number.isInteger(value) ? 0 : 2;
  return new Intl.NumberFormat(lng, {
    style: 'currency',
    currency: currencyOverride || currency || 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits,
    maximumFractionDigits: 2,
    ...options,
  }).format(value);
});

i18n.services.formatter?.add('shortDate', (value, lng, options) =>
  new Intl.DateTimeFormat(lng, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    ...options,
  }).format(new Date(value)),
);

export const useTranslationPrefix = (prefix: string) => {
  const { t } = useTranslation();
  return (key: string, options?: TOptions) => t(`${prefix}.${key}`, options);
};

export default i18n;
