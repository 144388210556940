import React, { FC } from 'react';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

import typography from 'infrastructure/targets/web/theme/typography';

import { StyledBackButton, StyledLink } from './style';

interface IBackButton {
  backButtonCallback?: () => void;
}
const BackButton: FC<IBackButton> = ({ backButtonCallback }) => (
  <StyledLink onClick={backButtonCallback}>
    <StyledBackButton sx={typography.button?.large}>
      <ChevronLeftOutlinedIcon sx={{ fontSize: '1.5rem', mr: 2 }} />
      Back
    </StyledBackButton>
  </StyledLink>
);

export default BackButton;
