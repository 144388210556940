import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledNavigationBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: '0 1 auto',
  minHeight: '48px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: '88px',
    flexDirection: 'row',
  },
}));

export const StyledInputContainer = styled(Box)(({ theme }) => ({
  marginLeft: 0,
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 'auto',
  },
}));
