import { Box } from '@mui/material';
import React, { FC } from 'react';

import { getFoodImagePlaceholder } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

export interface FoodImagePlaceholderValue {
  variant: 'small' | 'large';
}

const FoodImagePlaceholder: FC<FoodImagePlaceholderValue> = ({ variant }) => {
  const imageSizeLarge = { width: '126px', height: '126px' };
  const imageSizeSmall = { width: '58px', height: '58px' };

  const imageSize = variant === 'small' ? imageSizeSmall : imageSizeLarge;
  const padding = variant === 'small' ? '0px' : '24px';

  return (
    <Box
      component={'img'}
      src={getFoodImagePlaceholder()}
      sx={{ ...imageSize, padding, borderRadius: '8px', objectFit: 'cover' }}
    />
  );
};
export default FoodImagePlaceholder;
