import { Container } from 'inversify';

import infrastructureModule from 'infrastructure/infrastructure.module';
import reduxContainerModule from 'infrastructure/redux/redux.module';

import bookingWizardContainerModule from './modules/bookingWizard/container';

const container = new Container();

container.load(infrastructureModule);
container.load(reduxContainerModule);
container.load(bookingWizardContainerModule);

export default container;
