import { useInjection } from 'inversify-react';
import { useCallback } from 'react';
import { LocationTypes } from 'common';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import { WizardStep } from 'domain/entities/WizardStep';
import { InjectableHook, useHookInjection } from 'domain/hooks';

import {
  GetSimulatorLocationsAdapter,
  IGetSimulatorLocationsHook,
} from './useCaseGetSimulatorLocations';
import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';

export const SelectExperienceAdapter = Symbol('SelectExperienceAdapter');

export type ISelectExperienceAdapter = InjectableHook<{
  saveSelectedLocationToStore: (selectedLocation: LocationTypes.SimulatorLocation) => void;
  saveExperienceTypeToStore: (experienceType: string) => void;
}>;

const useCaseSelectExperience = (currentStep: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );
  const adapter = useInjection<ISelectExperienceAdapter>(SelectExperienceAdapter);

  const { saveSelectedLocationToStore, saveExperienceTypeToStore } = adapter();

  const getLocationsHook = useHookInjection<IGetSimulatorLocationsHook>(
    GetSimulatorLocationsAdapter,
  );
  const locations = getLocationsHook.result;

  const preselectLocation = useCallback(
    (locationSlug: string | null) => {
      const preselectedLocation = locations?.find((location) => location.urlSlug === locationSlug);
      if (preselectedLocation) {
        saveSelectedLocationToStore(preselectedLocation);
      }
    },

    [locations],
  );
  const redirectToSelectedExperience = (
    experienceSlug: string,
    partySize?: string,
    reservationDate?: string,
  ) => {
    if (experienceSlug) {
      saveExperienceTypeToStore(experienceSlug);
      switch (experienceSlug) {
        case BookingWizardFlowTypes.Lesson:
          bookingWizardNavigation.redirectToStep(WizardStep.selectInstructor);
          break;
        case BookingWizardFlowTypes.Simulator:
        case BookingWizardFlowTypes.Bowling:
          if (partySize && reservationDate) {
            bookingWizardNavigation.redirectToStep(WizardStep.sessionLength);
          } else {
            bookingWizardNavigation.redirectToStep(WizardStep.reservationDate);
          }
          break;
      }
    }
  };

  return {
    preselectLocation,
    redirectToSelectedExperience,
    nextStep: (experienceType: string) => {
      switch (experienceType) {
        case BookingWizardFlowTypes.Lesson:
          bookingWizardNavigation.redirectToStep(WizardStep.selectInstructor);
          break;
        case BookingWizardFlowTypes.Simulator:
        case BookingWizardFlowTypes.Bowling:
          bookingWizardNavigation.redirectToStep(WizardStep.reservationDate);
          break;
      }
    },

    prevStep: () => {
      bookingWizardNavigation.redirectToPrevStep(currentStep);
    },
  };
};

export default useCaseSelectExperience;
