import { Box, CircularProgress, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { StoreTypes } from 'common';
import { Formik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import useCaseGetFoodItemList from 'application/modules/bookingWizard/useCases/hooks/useCaseGetFoodItemList';
import { WizardStep } from 'domain/entities/WizardStep';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  setConfirmedBooking,
  setLocation,
  setRedirectTo,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch } from 'infrastructure/redux/store/hooks';
import { FoodCard } from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodCard/FoodCard';
import FoodTabPanel from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodCard/FoodTabPanel/FoodTabPanel';
import FoodSummaryCard from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodSummaryCard/FoodSummaryCard';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { StyledFoodContainer, StyledFormContainer, StyledFormContent } from './style';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DEFAULT_CARD_HEIGHT = 200;
const MOBILE_CARD_HEIGHT = 190;
const FOOD_CARD_GAP = 24;
const MAX_ITEMS = 600;
interface IPreOrderFoodStep {
  stepName: WizardStep;
}

const PreOrderFoodStep: FC<IPreOrderFoodStep> = () => {
  const t = useTranslationPrefix('pre_order_food');
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [foodCardSize, setFoodCardSize] = useState<number>(DEFAULT_CARD_HEIGHT + FOOD_CARD_GAP);

  const {
    inProgress,
    result,
    nextPageCursor,
    fetchFoodItemList,
    foodCategoryList,
    fetchFoodCategoryList,
  } = useCaseGetFoodItemList();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const [getAppoinment, { data, fulfilledTimeStamp }] = mboAPI.useLazyGetAppointmentQuery();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  // Extract query parameters from URL
  const appointmentId = query.get('appointmentId'); // Use this to submit the order from mobile
  const locationId = query.get('locationId');
  const redirectUri = query.get('redirectUri');

  const [tabValue, setTabValue] = useState(0);
  const [pagination, setPagination] = useState<{ limit?: number; cursor?: string | null }>({
    limit: 10,
    cursor: undefined,
  });
  const [products, setProducts] = useState<StoreTypes.Product[]>([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (isMediumDown) {
      setFoodCardSize(MOBILE_CARD_HEIGHT + FOOD_CARD_GAP);
    } else {
      setFoodCardSize(DEFAULT_CARD_HEIGHT + FOOD_CARD_GAP);
    }
  }, [isMediumDown]);

  useEffect(() => {
    fetchFoodCategoryList();
  }, []);

  useEffect(() => {
    if (locationId) dispatch(setLocation(locationId));
  }, [locationId]);

  useEffect(() => {
    if (appointmentId) {
      getAppoinment(appointmentId);
    }
  }, [appointmentId]);

  useEffect(() => {
    if (data && redirectUri) {
      dispatch(setConfirmedBooking([data]));
      dispatch(setRedirectTo(decodeURIComponent(redirectUri)));
    }
  }, [fulfilledTimeStamp]);

  useEffect(() => {
    if (!foodCategoryList || foodCategoryList.length === 0) {
      return;
    }
    fetchFoodItemList(foodCategoryList[tabValue].id, pagination);
  }, [foodCategoryList, tabValue, pagination]);

  useEffect(() => {
    if (typeof result === 'undefined') {
      return;
    }
    setProducts([...products, ...result]);
    setHasMore(nextPageCursor !== null);
  }, [result, nextPageCursor]);

  const handleTabChange = (_event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
    setProducts([]);
    setPagination({ limit: 10, cursor: undefined });
    setHasMore(true);
  };

  const loadMoreItems = async () => {
    if (inProgress || !hasMore) return;

    setPagination((prev) => ({
      ...prev,
      cursor: nextPageCursor,
    }));
  };

  const isItemLoaded = (index: number) => !hasMore || !products || index < products.length;

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const product = products?.[index];
    if (!product) return null;

    return (
      <Box style={style} display={'flex'} flexDirection={'column'} gap={5} key={product.id}>
        {product.variations.map((variation, idx) => (
          <FoodCard
            key={product.id + variation.id + idx}
            name={`${product.name} - ${variation.name}`}
            productId={product.id}
            variantId={variation.id}
            title={`${product.name} - ${variation.name}`}
            subtitle={product.description}
            attributes={product.attributes}
            price={Number(variation.price)}
            imageUrl={variation.imageUrl[0]}
          />
        ))}
      </Box>
    );
  };

  return (
    <PageLayout pageTitle={t('heading')} shouldFadeIn>
      {redirectUri ? null : <NavigationBar backButtonCallback={() => navigate(-1)} />}

      <Formik
        initialValues={{
          order: {},
          notes: '',
        }}
        // eslint-disable-next-line arrow-body-style
        onSubmit={() => {
          return;
        }}
        enableReinitialize
        validateOnMount>
        {() => (
          <StyledFormContainer>
            <StyledFormContent sx={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
              <StyledFoodContainer>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    aria-label="Food Category tabs"
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    {foodCategoryList?.map((category, index) => (
                      <Tab key={category.id} label={category.name} {...a11yProps(index)} />
                    ))}
                  </Tabs>
                </Box>

                {products?.length === 0 && inProgress && (
                  <Box display="flex" justifyContent="center" mt={6}>
                    <CircularProgress />
                  </Box>
                )}

                {products?.length > 0 && (
                  <FoodTabPanel value={tabValue} index={tabValue} sx={{ mt: 6 }}>
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={MAX_ITEMS}
                      loadMoreItems={loadMoreItems}>
                      {({ onItemsRendered, ref }) => (
                        <List
                          height={2.5 * foodCardSize}
                          width="100%"
                          itemCount={products.length}
                          itemSize={(idx: number) => {
                            if (products[idx]) {
                              return products[idx].variations.length * foodCardSize;
                            }
                            return foodCardSize;
                          }}
                          onItemsRendered={onItemsRendered}
                          ref={ref}>
                          {Row}
                        </List>
                      )}
                    </InfiniteLoader>
                    {inProgress && (
                      <Box display="flex" justifyContent="center" mt={6}>
                        <CircularProgress />
                      </Box>
                    )}
                  </FoodTabPanel>
                )}
              </StyledFoodContainer>
              <FoodSummaryCard sx={{ width: '400px' }} />
            </StyledFormContent>
          </StyledFormContainer>
        )}
      </Formik>
    </PageLayout>
  );
};

export default PreOrderFoodStep;
