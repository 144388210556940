import { Button, useTheme } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Box } from '@mui/system';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { useLocation } from 'react-router';

import { WizardStep } from 'domain/entities/WizardStep';
import PageLayout from 'infrastructure/components/PageLayout';
import useCaseNavigateBookingWizard from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import SectionTitle from 'infrastructure/components/SectionTitle';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { setLocation, setLocationDetails } from 'infrastructure/redux/slices/bookingWizard.slice';
import { selectLocation } from 'infrastructure/redux/slices/bookingWizard.selector';
import useCaseGetSimulatorLocations from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';

import { StyledSection600Column } from './style';

interface ISelectLocationStep {
  stepName: WizardStep;
}

interface ILocationForm {
  location: string;
}

const SelectLocationStep: FC<ISelectLocationStep> = ({ stepName }) => {
  const { nextStep } = useCaseNavigateBookingWizard(stepName);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const selectedLocationId = useAppSelector(selectLocation);
  const { result: locations, error } = useCaseGetSimulatorLocations();

  const [preselectedLocationId, setPreselectedLocationId] = useState<string>();

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const locationSlug = query?.get('location');

  useEffect(() => {
    if (locations && locationSlug) {
      const locationToPreselect = locations?.find((location) => location.urlSlug === locationSlug);
      if (locationToPreselect) {
        setPreselectedLocationId(locationToPreselect.id);
        dispatch(
          setLocationDetails(
            locations?.find((location) => location?.id === locationToPreselect?.id),
          ),
        );
      }
    }
  }, [locations, error]);

  const validationSchema = useMemo(
    () =>
      object().shape({
        location: string()
          .required()
          .test('empty-check', 'Location cannot be empty', (locId) =>
            locId ? locId?.length >= 0 : false,
          ),
      }),
    [locations],
  );

  const locationForm = useFormik<ILocationForm>({
    initialValues: {
      location: (!preselectedLocationId ? selectedLocationId : preselectedLocationId) || '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      dispatch(setLocation(values?.location));
      dispatch(
        setLocationDetails(locations?.find((location) => location?.id === values?.location)),
      );
      nextStep();
    },
  });

  return (
    <PageLayout pageTitle={'Book Your Next Visit'} shouldFadeIn>
      <StyledSection600Column>
        <SectionTitle
          title={'Select Your Location'}
          icon={<FmdGoodIcon style={{ color: theme.palette.secondary.main }} />}
        />
        <Box component="form" sx={{ pl: { xs: '0px', sm: '62px' }, mt: 2 }}>
          <FormikProvider value={locationForm}>
            <LocationSelect
              onChange={locationForm.handleChange}
              fullWidth
              selectedSimLocationId={
                !preselectedLocationId ? selectedLocationId : preselectedLocationId
              }
            />
            <Box sx={{ mt: 5 }}>
              <Button
                variant="contained"
                color="primary"
                className="nextButton"
                size="large"
                onClick={() => locationForm.handleSubmit()}
                disabled={!locationForm.isValid || !locations || !locations?.length}
                sx={{ float: 'right' }}>
                Next
              </Button>
            </Box>
          </FormikProvider>
        </Box>
      </StyledSection600Column>
    </PageLayout>
  );
};

export default SelectLocationStep;
