import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledContentContainer = styled(Box)(({ theme }) => ({
  maxWidth: '920px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('sm')]: {},
}));

export const StyledCardsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '920px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  minHeight: '400px',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
    gap: theme.spacing(5),
  },
}));

export const StyledTitleContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
