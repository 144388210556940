import { IMyBooking } from 'domain/entities/ManageBooking';
import { AsyncHookResult } from 'domain/hooks';
import { Entity } from 'domain/types';

export interface IManageBookingRepository {
  useListMyBookings(isUS: boolean): AsyncHookResult<Entity<IMyBooking>[]> & {
    query(): void;
  };
  useListMyUpcomingBookings(isUS: boolean): AsyncHookResult<Entity<IMyBooking>[]> & {
    query(): void;
  };
}

export const IManageBookingRepository = Symbol('ManageBookingRepository');
