import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from 'infrastructure/redux/store';

const InfrastructureProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor}>{children}</PersistGate>
  </ReduxProvider>
);

export default InfrastructureProvider;
