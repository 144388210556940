import { Button, CardMedia, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { StyledBookingCard, StyledBookingCardContent, StyledCardActions } from './style';

interface IBookingCard {
  imageUrl: string;
  content: ReactNode;
  title: string;
  experienceType?: string;
  onCardButtonClick?: (value: string) => void;
}
const BookingCard: FC<IBookingCard> = ({
  imageUrl,
  title,
  content,
  onCardButtonClick,
  experienceType,
}) => {
  const handleClick = () => {
    if (onCardButtonClick && experienceType) {
      onCardButtonClick(experienceType);
    }
  };

  return (
    <StyledBookingCard>
      <CardMedia component="img" height="240" image={imageUrl} alt={title} />
      <StyledBookingCardContent>
        <Typography sx={{ mt: 5, mb: 4 }} variant="h4">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ minHeight: { xs: 208, md: 176 } }}>
          {content}
        </Typography>
      </StyledBookingCardContent>
      <StyledCardActions>
        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{ width: '100%' }}
          onClick={handleClick}>
          Book Now
        </Button>
      </StyledCardActions>
    </StyledBookingCard>
  );
};

export default BookingCard;
