import { Variants } from 'common';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { selectIsDarkMode } from 'infrastructure/redux/slices/theme.selector';
import { toggleColorMode } from 'infrastructure/redux/slices/theme.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';

import appTheme from './theme';

interface IColorModeContext {
  mode: string;
  colorMode: { toggleColorMode: () => void };
}

export const ColorModeContext = createContext<IColorModeContext>({
  mode: 'light',
  colorMode: { toggleColorMode: () => null },
});

interface IColorModeProvider {
  children: ReactNode;
}

const theme = appTheme(process.env.VITE_VARIANT as Variants);

export const ColorModeProvider: FC<IColorModeProvider> = ({ children }) => {
  const dispatch = useAppDispatch();
  const isDarkMode: boolean | undefined = useAppSelector(selectIsDarkMode);
  const [mode, setMode] = useState<'light' | 'dark'>(isDarkMode ? 'dark' : 'light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  useEffect(() => {
    dispatch(toggleColorMode({ isDarkTheme: mode === 'dark' }));
  }, [mode]);

  const defaultTheme = useMemo(
    () => ({
      ...theme,
      palette: {
        ...theme.palette,
        mode: mode,
      },
    }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={{ mode, colorMode }}>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
