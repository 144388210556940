import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StoreTypes } from 'common';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { PreOrderFoodFormValues } from 'application/modules/bookingWizard/useCases/hooks/useCasePreOrderFood';
import FoodAttribute from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodCard/FoodAttribute/FoodAttribute';
import FoodNumberCounter from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodCard/FoodNumberCounter/FoodNumberCounter';
import FoodImagePlaceholder from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodImagePlaceholder/FoodImagePlaceholder';
import { formatToCurrency } from 'infrastructure/targets/web/modules/common/helpers';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import {
  StyledAttributeContainer,
  StyledContentCardContainer,
  StyledSubtitleContainer,
} from './style';

export const FoodCard = ({
  name,
  productId,
  variantId,
  title,
  subtitle,
  attributes,
  price,
  imageUrl,
}: {
  name: string;
  title: string;
  productId: string;
  variantId: string;
  subtitle?: string;
  attributes: StoreTypes.AttributeTypes[];
  price?: number;
  imageUrl?: string;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const t = useTranslationPrefix('pre_order_food.card');
  const [counterVisible, setCounterVisible] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<PreOrderFoodFormValues>();

  const imageSizeDesktop = { width: '126px', height: '126px' };
  const imageSizeMobile = { width: '58px', height: '58px' };
  const imageSize = isMobile ? imageSizeMobile : imageSizeDesktop;

  const handleClick = () => {
    setCounterVisible(true);
    setFieldValue(`order.${name.replace(/\./g, '')}`, {
      name,
      productId,
      variantId,
      title,
      subtitle,
      price,
      imageUrl,
      quantity: 1,
    });
  };

  const onCounterClick = (value: number) => {
    if (value === 0) {
      setCounterVisible(false);
      setFieldValue(`order.${name.replace(/\./g, '')}`, undefined);
    } else {
      setFieldValue(`order.${name.replace(/\./g, '')}`, {
        name,
        productId,
        variantId,
        title,
        subtitle,
        price,
        imageUrl,
        quantity: value,
      });
    }
  };

  useEffect(() => {
    if (!counterVisible) {
      const value = values.order[name.replace(/\./g, '')];
      if (value && value.quantity > 0) {
        setCounterVisible(true);
      }
    }

    if (counterVisible) {
      const value = values.order[name.replace(/\./g, '')];
      if (!value || value.quantity === 0) {
        setCounterVisible(false);
      }
    }
  }, [counterVisible, name, values]);

  return (
    <StyledContentCardContainer>
      <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection={'column'} gap={1} mt={2}>
          <Box>
            <Typography variant="subtitle2" fontWeight={'bold'}>
              {title}
            </Typography>
          </Box>

          {subtitle || attributes.length > 0 ? (
            <StyledSubtitleContainer>
              <Typography variant="body2" color="text.secondary" maxHeight={48} overflow={'clip'}>
                {subtitle}
              </Typography>
              <StyledAttributeContainer>
                {attributes.map((attr, key) => (
                  <FoodAttribute key={key} type={attr} />
                ))}
              </StyledAttributeContainer>
            </StyledSubtitleContainer>
          ) : null}
        </Box>
        <Box display={'flex'}>
          {imageUrl ? (
            <Box
              component={'img'}
              src={imageUrl}
              sx={{ ...imageSize, borderRadius: '8px', objectFit: 'cover' }}
            />
          ) : (
            <FoodImagePlaceholder variant={isMobile ? 'small' : 'large'} />
          )}
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} gap={4} mt={0} justifyContent={'space-between'}>
        <Box>
          <Typography fontWeight={'bold'}>{price ? formatToCurrency(price) : 'N/A'}</Typography>
        </Box>
        <Box>
          {!counterVisible && (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<AddOutlinedIcon />}
              onClick={handleClick}>
              {t('add_to_order')}
            </Button>
          )}
          {counterVisible && (
            <FoodNumberCounter
              name={`order.${name.replace(/\./g, '')}.quantity`}
              min={0}
              max={100}
              onChange={onCounterClick}
            />
          )}
        </Box>
      </Box>
    </StyledContentCardContainer>
  );
};
