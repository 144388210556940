import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { WizardStep } from 'domain/entities/WizardStep';
import { useGtmEvent } from 'infrastructure/providers/event/hooks/useGtmEvent';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import useCaptureEmailFromURL from 'infrastructure/targets/web/common/useCaptureEmailFromURL';
import BookingWizardContainer from 'infrastructure/targets/web/modules/bookingWizard/components/BookingWizardContainer';
import CheckYourEmailStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/CheckYourEmailStep';
import ChooseATimeStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ChooseATimeStep';
import ConfirmReservationStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ConfirmReservationStep';
import CreateAccountStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/CreateAccountStep';
import MobilePricingStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/MobilePricingStep';
import PaymentDetailsStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/PaymentDetailsStep';
import PreOrderFoodStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep';
import ReservationCompleteStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ReservationCompleteStep';
import ReservationDateStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/ReservationDateStep';
import SelectExperienceStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectExperienceStep';
import SelectInstructorStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectInstructorStep';
import SelectLocationStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectLocationStep';
import SessionLengthStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep';
import SignInStep from 'infrastructure/targets/web/modules/bookingWizard/Steps/SignInStep';

const BookingWizard: FC = () => {
  useCaptureEmailFromURL();
  const params = useParams();
  const { stepName } = params;
  const { sendGtmEvent } = useGtmEvent();
  const currentUser = useAppSelector(selectUserSummary);
  const [currentStep, setCurrentStep] = useState(
    (stepName as WizardStep) || WizardStep.selectLocation,
  );
  let email = localStorage.getItem('email');
  let firstName = 'Guest';
  let lastName = 'User';

  useEffect(() => {
    setCurrentStep(stepName as WizardStep);

    if (currentUser?.email || email) {
      if (currentUser.email) {
        email = currentUser.email;
        firstName = currentUser.firstName;
        lastName = currentUser.lastName;
      }
      let eventName = '';
      switch (stepName) {
        case WizardStep.selectLocation:
          eventName = 'Select Location Screen Viewed';
          break;
        case WizardStep.reservationDate:
          eventName = 'Reservation Date Screen Viewed';
          break;
        case WizardStep.selectExperience:
          eventName = 'Select Experience Screen Viewed';
          break;
        case WizardStep.selectInstructor:
          eventName = 'Select Instructor Screen Viewed';
          break;
        case WizardStep.chooseLessonTime:
          eventName = 'Choose Lesson Time Screen Viewed';
          break;
        case WizardStep.sessionLength:
          eventName = 'Session Length Screen Viewed';
          break;
        case WizardStep.checkoutSignIn:
          eventName = 'Checkout Sign In Screen Viewed';
          break;
        case WizardStep.createAccount:
          eventName = 'Create Account Screen Viewed';
          break;
        case WizardStep.checkoutCheckYourEmail:
          eventName = 'Checkout Check Your Email Screen Viewed';
          break;
        case WizardStep.paymentDetails:
          eventName = 'Payment Details Screen Viewed';
          break;
        case WizardStep.confirmReservation:
          eventName = 'Confirm Reservation Screen Viewed';
          break;
        case WizardStep.seeYouSoon:
          eventName = 'See You Soon Screen Viewed';
          break;
        case WizardStep.pricing:
          eventName = 'Pricing Screen Viewed';
          break;
        case WizardStep.preOrderFood:
          eventName = 'Pre-Order Food Screen Viewed';
          break;
        default:
          eventName = '';
          break;
      }

      if (eventName) {
        sendGtmEvent({
          eventName: `Web - ${eventName}`,
          data: `Web - ${eventName}`,
          firstName: firstName,
          lastName: lastName,
          email: email,
        });
      }
    }
  }, [stepName, currentUser?.email]);

  return (
    <BookingWizardContainer currentStep={currentStep as WizardStep}>
      <SelectLocationStep stepName={WizardStep.selectLocation} />
      <SelectExperienceStep stepName={WizardStep.selectExperience} />
      <SelectInstructorStep stepName={WizardStep.selectInstructor} />
      <ReservationDateStep stepName={WizardStep.reservationDate} />
      <ChooseATimeStep stepName={WizardStep.chooseLessonTime} />
      <SessionLengthStep stepName={WizardStep.sessionLength} />
      <SignInStep stepName={WizardStep.checkoutSignIn} />
      <CreateAccountStep stepName={WizardStep.createAccount} />
      <CheckYourEmailStep stepName={WizardStep.checkoutCheckYourEmail} />
      <PaymentDetailsStep stepName={WizardStep.paymentDetails} />
      <ConfirmReservationStep stepName={WizardStep.confirmReservation} />
      <ReservationCompleteStep stepName={WizardStep.seeYouSoon} />
      <MobilePricingStep stepName={WizardStep.pricing} />
      <PreOrderFoodStep stepName={WizardStep.preOrderFood} />
    </BookingWizardContainer>
  );
};

export default BookingWizard;
