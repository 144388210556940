import { Field, FieldProps, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { FC, useCallback } from 'react';

import NumberCounter, {
  NumberCounterProps,
} from 'infrastructure/components/NumberCounter/NumberCounter';

export interface FormikNumberCounter extends NumberCounterProps {
  name: string;
  onChange?: (value: number) => void;
}

const FormikNumberCounter: FC<FormikNumberCounter> = (props) => {
  const { values } = useFormikContext<unknown>();
  const value = get(values, props.name);

  // Declare the callback outside of the Field render function
  const handleChange = useCallback(
    (value: number, setFieldTouched: (field: string) => void, onChange: (event: any) => void) => {
      setFieldTouched(props.name);

      onChange({
        target: { value, name: props.name, type: 'number' },
      });

      if (props.onChange) {
        props.onChange(value);
      }
    },
    [props.name, props.onChange],
  );

  return (
    <Field name={props.name} required={props.required}>
      {({ field, form: { touched, errors, setFieldTouched } }: FieldProps) => {
        const hasError = !!get(errors, field.name) && !!get(touched, field.name);

        return (
          <NumberCounter
            {...field}
            {...props}
            value={value}
            data-testid={props.name}
            onChange={(value) => handleChange(value, setFieldTouched, field.onChange)}
            error={hasError}
            helperText={hasError ? get(errors, field.name)?.toString() : undefined}
          />
        );
      }}
    </Field>
  );
};

export default FormikNumberCounter;
