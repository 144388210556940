import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSection600Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '600px',
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
