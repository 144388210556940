import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

import useCaseGetSimulatorLocations from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { useCaseListMyBookings } from 'application/modules/myBookings/useCases/useCaseListMyBookings';
import { AppointmentTypes } from 'domain/entities/ManageBooking';
import Loader from 'infrastructure/components/Loader';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import SectionTitle from 'infrastructure/components/SectionTitle';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import BookingSummaryCard from 'infrastructure/targets/web/modules/bookingWizard/components/BookingSummaryCard/BookingSummaryCard';
import { isUS } from 'infrastructure/targets/web/modules/common/helpers';
import {
  getBowlingBookingImage,
  getLessonBookingImage,
  getSimBookingImage,
} from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

import { StyledSectionColumn } from './ManageBookings.style';

const resolveImage = (type: AppointmentTypes) => {
  switch (type) {
    case AppointmentTypes.RENTAL:
    case AppointmentTypes.PARTY_RENTAL:
      return getSimBookingImage();

    case AppointmentTypes.BOWLING:
    case AppointmentTypes.PARTY_BOWLING:
      return getBowlingBookingImage();
    case AppointmentTypes.CLASS:
      return getLessonBookingImage();
    default:
      return getLessonBookingImage();
  }
};

const resolveTitle = (type: AppointmentTypes) => {
  switch (type) {
    case AppointmentTypes.RENTAL:
      return 'Simulator Rental';
    case AppointmentTypes.GROUP:
      return 'Small Group Lesson';
    case AppointmentTypes.PRIVATE:
      return 'Lesson Booking';
    case AppointmentTypes.INTRO:
      return 'Swing Evaluation';
    case AppointmentTypes.CLASS:
      return 'Class';
    case AppointmentTypes.PARTY_RENTAL:
      return `Party Rental (2 Simulators)`;
    case AppointmentTypes.BOWLING:
      return 'Duckpin Lane Rental';
    case AppointmentTypes.PARTY_BOWLING:
      return `Party Rental (2 Lanes)`;
    default:
      return 'Unknown Appointment type';
  }
};

const ManageBookingsView: FC = () => {
  const navigate = useNavigate();
  const { error, result, inProgress, query } = useCaseListMyBookings(isUS);
  const user = useAppSelector(selectUserSummary);
  const { navigateToSignInPage } = useCaseExternalNavigation();
  const [cancelAppointment, { fulfilledTimeStamp }] = mboAPI.useCancelAppointmentMutation();

  const handleCancelButton = (appointmentId: number[], locationId: string) => {
    if (locationId) {
      cancelAppointment({ locationId, body: { appointmentId: appointmentId } });
    }
  };

  const { result: locations } = useCaseGetSimulatorLocations();

  const HandleGetPhoneNumber = (locationId: string) => {
    const matchLocation = locations?.find((loc) => locationId === loc.id);
    return matchLocation?.telephone ?? '315-613-4653';
  };

  useEffect(() => {
    if (fulfilledTimeStamp) {
      query();
    }
  }, [fulfilledTimeStamp]);

  useEffect(() => {
    if (!user?.email) {
      navigateToSignInPage();
    } else {
      query();
    }
  }, []);

  return (
    <PageLayout pageTitle={'Manage Bookings'} shouldFadeIn>
      <StyledSectionColumn>
        <NavigationBar backButtonCallback={() => navigate(-1)} />
        <SectionTitle title={'My Bookings'} />
        <Box component="form" sx={{ pl: { xs: '0px', sm: '62px' } }}></Box>

        {inProgress ? (
          <Box display={'flex'} justifyContent={'center'} mt={4}>
            <Loader />
          </Box>
        ) : (
          <Stack width={'100%'} mt={4} gap={5}>
            {error && <Box>{error}</Box>}
            {result?.length ? (
              result.map((booking) => (
                <BookingSummaryCard
                  key={booking.id}
                  bookingDetails={{
                    locationId: booking.locationId,
                    image: booking.image || resolveImage(booking.type),
                    title: resolveTitle(booking.type),
                    bookingDate: booking.bookingDate,
                    rawTime: booking.rawTime,
                    location: booking.location,
                    instructor: booking.instructor,
                    startTime: booking.startTime,
                    sessionLength: booking.sessionLength,
                    price: booking.paidWithPoints ? booking.priceInPoints : booking.price,
                    paidWithPoints: booking.paidWithPoints,
                    bookingIds: booking.bookingIds,
                    type: booking.type,
                  }}
                  onCancel={handleCancelButton}
                  onGetPhoneNumber={HandleGetPhoneNumber}
                />
              ))
            ) : (
              <Box>No bookings found</Box>
            )}
          </Stack>
        )}
      </StyledSectionColumn>
    </PageLayout>
  );
};

export default ManageBookingsView;
