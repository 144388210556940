import { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import useCaseVerifyUser from 'application/modules/auth/useCases/hooks/useCaseVerifyUser';

const Verification: FC = () => {
  const { verifyUser } = useCaseVerifyUser();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const token = query.get('token');
  const email = query.get('email');
  const bookingUUID = query.get('booking');
  const useMobile = query.get('useMobile');

  useEffect(() => {
    verifyUser(token, email, bookingUUID, Boolean(useMobile));
  }, [token, email]);

  return null;
};

export default Verification;
