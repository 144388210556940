import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'infrastructure/redux/store';

import { ThemeState } from './theme.slice';

export const selectSelf = (state: RootState): RootState => state;

export const selectThemeState = createSelector(
  selectSelf,
  (state: RootState): ThemeState => state.theme,
);

export const selectIsDarkMode = createSelector(
  selectThemeState,
  (theme: ThemeState) => theme.isDarkTheme,
);
