import { TextField } from '@mui/material';
import { styled } from '@mui/system';

export const StyledDateInputField = styled(TextField)(({ theme }) => ({
  minWidth: '200px',
  '& .MuiOutlinedInput-input': { height: '100%' },
  '& .MuiInputBase-root': { height: '100%' },
  flex: 2,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
