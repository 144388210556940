import { useInjection } from 'inversify-react';

import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';

export const GetSimulatorAvailabilityAdapter = Symbol('GetSimulatorAvailabilityAdapter');
export type IGetSimulatorAvailabilityAdapter = InjectableHook<
  AsyncHookResult<Array<Date>, unknown> & {
    fetchFurtherAvailableDates: (newDate: Date | null) => void;
    nextStep: (partySize: string) => void;
  }
>;

const useCaseSelectSimulatorReservationDate = (currentStep?: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );

  const adapter = useInjection<IGetSimulatorAvailabilityAdapter>(GetSimulatorAvailabilityAdapter);
  const { error, result, inProgress, fetchFurtherAvailableDates, nextStep } = adapter();

  return {
    fetchFurtherAvailableDates,
    inProgress,
    error,
    result,
    nextStep,
    prevStep: () => {
      bookingWizardNavigation.redirectToPrevStep(currentStep || WizardStep.reservationDate);
    },
  };
};

export default useCaseSelectSimulatorReservationDate;
