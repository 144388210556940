import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledContentCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.other.outlineBorder}`,
  borderRadius: '8px',
  padding: theme.spacing(4, 4, 4, 4),
  width: '100%',
  justifyContent: 'space-between',
  gap: theme.spacing(2),

  height: '200px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: '190px',
  },
}));

export const StyledSubtitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  overflow: 'hidden',
}));

export const StyledAttributeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),

  height: '24px',
}));
