import { Card, Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBookingCard = styled(Card)(() => ({
  maxWidth: 450,
  minWidth: 300,
  height: 600,
}));

export const StyledBookingCardContentSpacer = styled('span')(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  display: 'flex',
}));

export const StyledCardActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(0, 5, 5, 5),
}));

export const StyledBookingCardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 5, 0, 5),
  height: 250,
}));
