import { Typography } from '@mui/material';
import React, { FC } from 'react';

import useCaseNavigateBookingWizard from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import { WizardStep } from 'domain/entities/WizardStep';
import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import { StyledSummaryDataContainer } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/style';
import { getPreOrderFoodImage } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

export type PreOrderFoodContainer = Record<string, never>;

const PreOrderFoodContainer: FC<PreOrderFoodContainer> = () => {
  const t = useTranslationPrefix('book.see_you_soon');

  const { moveToStep } = useCaseNavigateBookingWizard(WizardStep.seeYouSoon);

  return (
    <StyledSummaryDataContainer>
      <HorizontalCard
        variant={HorizontalCardType.Small}
        title={t('pre_order_food_heading')}
        noBottomMargin
        handleCTA={() => moveToStep(WizardStep.preOrderFood)}
        ctaContent={t('pre_order_food_start_order_btn')}
        content={
          <Typography variant="body2" color="text.secondary">
            {t('pre_order_food_description')}
          </Typography>
        }
        imageUrl={getPreOrderFoodImage()}
      />
    </StyledSummaryDataContainer>
  );
};
export default PreOrderFoodContainer;
