import { LocationTypes } from 'common';

import { ISelectExperienceAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectExperience';
import { useAppDispatch } from 'infrastructure/redux/store/hooks';
import {
  setExperienceType,
  setLocation,
  setLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.slice';

const useSelectExperience: ISelectExperienceAdapter = () => {
  const dispatch = useAppDispatch();
  const handleSaveSelectedLocationToStore = (selectedLocation: LocationTypes.SimulatorLocation) => {
    dispatch(setLocationDetails(selectedLocation));
    dispatch(setLocation(selectedLocation.id));
  };

  const handleSaveExperienceTypeToStore = (selectedExperience: string) => {
    dispatch(setExperienceType({ experienceType: selectedExperience }));
  };

  return {
    saveSelectedLocationToStore: handleSaveSelectedLocationToStore,
    saveExperienceTypeToStore: handleSaveExperienceTypeToStore,
  };
};

export default useSelectExperience;
