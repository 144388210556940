import { Box } from '@mui/material';
import React, { FC } from 'react';

import { ReactComponent as Express } from 'infrastructure/targets/web/assets/icons/american-express.svg';
import { ReactComponent as DefaultCard } from 'infrastructure/targets/web/assets/icons/default.svg';
import { ReactComponent as Club } from 'infrastructure/targets/web/assets/icons/diners-club.svg';
import { ReactComponent as Discover } from 'infrastructure/targets/web/assets/icons/discover.svg';
import { ReactComponent as Elo } from 'infrastructure/targets/web/assets/icons/elo.svg';
import { ReactComponent as Hipercard } from 'infrastructure/targets/web/assets/icons/hipercard.svg';
import { ReactComponent as Jcb } from 'infrastructure/targets/web/assets/icons/jcb.svg';
import { ReactComponent as Maestro } from 'infrastructure/targets/web/assets/icons/maestro.svg';
import { ReactComponent as Mastercard } from 'infrastructure/targets/web/assets/icons/mastercard.svg';
import { ReactComponent as Unionpay } from 'infrastructure/targets/web/assets/icons/unionpay.svg';
import { ReactComponent as Visa } from 'infrastructure/targets/web/assets/icons/visa.svg';

interface ICardTypeIcon {
  cardType: string;
}

const CardTypeIcon: FC<ICardTypeIcon> = ({ cardType }) => {
  const svgProps = { width: 30, height: 30 };

  const knownCardTypes = [
    { name: 'american-express', component: <Express {...svgProps} /> },
    { name: 'diners-club', component: <Club {...svgProps} /> },
    { name: 'discover', component: <Discover {...svgProps} /> },
    { name: 'elo', component: <Elo {...svgProps} /> },
    { name: 'hipercard', component: <Hipercard {...svgProps} /> },
    { name: 'jcb', component: <Jcb {...svgProps} /> },
    { name: 'maestro', component: <Maestro {...svgProps} /> },
    { name: 'master card', component: <Mastercard {...svgProps} /> },
    { name: 'unionpay', component: <Unionpay {...svgProps} /> },
    { name: 'visa', component: <Visa {...svgProps} /> },
  ];

  const selectedCard = knownCardTypes.find(({ name }) => name === cardType.toLocaleLowerCase());

  return (
    <Box sx={{ height: '20px', display: 'flex', flexDirection: 'row' }}>
      {selectedCard ? selectedCard.component : <DefaultCard {...svgProps} />}
    </Box>
  );
};

export default CardTypeIcon;
