import { useInjection } from 'inversify-react';
import { useEffect } from 'react';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';
import useCaseGetSimulatorLocations from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import useEffectOnce from 'infrastructure/targets/web/modules/bookingWizard/hooks/useEffectOnce';

export const AppStartupAdapter = Symbol('AppStartupAdapter');

export enum RefreshTokenError {
  Unknown = 'unknown',
}

export interface RefreshTokenResponse {
  refreshToken: string;
}

export type IAppStartupAdapter = InjectableHook<
  AsyncHookResult<RefreshTokenResponse, RefreshTokenError> & {
    wasSuccesful: boolean;
    handleRefreshToken: () => void;
    handleUpdateAppTitle: () => void;
    saveNewTokensToStore: (newAccessToken: string, newRefreshToken: string) => void;
    newRefreshToken: string | undefined;
    newAccessToken: string | undefined;
    initializeFacebookPixel: () => void;
    initializeSentry: () => void;
  }
>;

const useCaseAppStartup = () => {
  const adapter = useInjection<IAppStartupAdapter>(AppStartupAdapter);
  const {
    inProgress,
    error,
    wasSuccesful,
    handleUpdateAppTitle,
    saveNewTokensToStore,
    newRefreshToken,
    newAccessToken,
    initializeFacebookPixel,
    initializeSentry,
  } = adapter();
  const { fetchLocations } = useCaseGetSimulatorLocations();

  useEffectOnce(() => {
    handleUpdateAppTitle();
    initializeFacebookPixel();
    initializeSentry();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (newRefreshToken && newAccessToken) {
      saveNewTokensToStore(newAccessToken, newRefreshToken);
    }
  }, [newRefreshToken, newAccessToken]);

  return {
    inProgress,
    error,
    result: wasSuccesful,
  };
};

export default useCaseAppStartup;
