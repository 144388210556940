import { Variants } from 'common';
import { createTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import typography from './typography';
import spacing from './spacing';
// FiveIron
import fiveIronComponents from './fiveiron/components';
import fiveIronPalette from './fiveiron/palette';
// DuckPin
import duckpinComponents from './duckpin/components';
import duckpinPalette from './duckpin/palette';

const resolveComponents = (variant?: Variants) => {
  switch (variant) {
    case Variants.DUCKPIN:
      return duckpinComponents;
    case Variants.FIVEIRON:
      return fiveIronComponents;
    default:
      return fiveIronComponents;
  }
};

const resolvePalette = (variant?: Variants) => {
  switch (variant) {
    case Variants.DUCKPIN:
      return duckpinPalette;
    case Variants.FIVEIRON:
      return fiveIronPalette;
    default:
      return fiveIronPalette;
  }
};

const theme = (variant?: Variants) =>
  createTheme({
    breakpoints,
    components: resolveComponents(variant),
    palette: resolvePalette(variant),
    spacing,
    typography,
  });

export default theme;
