import { Provider } from 'inversify-react';
import React, { FC, ReactNode } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import InfrastructureProvider from 'infrastructure/contexts/InfrastructureProvider';
import useCaseAppStartup from 'application/modules/bookingWizard/useCases/hooks/useCaseAppStartup';

import container from './container';
import Router from './routes/Router';
import { ColorModeProvider } from './theme/ColorModeProvider';
import { TextCopyContextProvider } from './providers/TextCopyContextProvider';
interface IAppWrapper {
  children?: ReactNode;
}

const AppWrapper: FC<IAppWrapper> = ({ children }) => {
  const { result, error } = useCaseAppStartup();
  if (!result && error) {
    console.error('Error while refreshing token');
  }
  return <>{children}</>;
};
export default function App() {
  return (
    <Provider container={container}>
      <InfrastructureProvider>
        <AppWrapper>
          <ColorModeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextCopyContextProvider>
                <Router />
              </TextCopyContextProvider>
            </LocalizationProvider>
          </ColorModeProvider>
        </AppWrapper>
      </InfrastructureProvider>
    </Provider>
  );
}
