import { useInjection } from 'inversify-react';
import { useEffect, useRef, useState } from 'react';

import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';
import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import { selectBookingForm } from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';

export const CheckYourEmailAdapter = Symbol('CheckYourEmailAdapter');

export type ICheckYourEmailAdapter = InjectableHook<
  AsyncHookResult<unknown, unknown> & {
    navigateToEmailProviderMailbox: (providerUrl: string, providedEmail?: string) => void;
    resendVerificationLink: (email: string, locationId: string, bookingUUID?: string) => void;
    navigateToGenericMailbox: () => void;
    isLoggedIn: boolean;
  }
>;

const commonEmailProviders = [
  { providerName: 'Gmail', provider: 'gmail', url: 'https://mail.google.com/mail/u/' },
  { providerName: 'Yahoo Mail', provider: 'yahoo', url: 'https://mail.yahoo.com/' },
  {
    providerName: 'Hotmail',
    provider: 'hotmail',
    url: 'https://outlook.live.com/owa/a/',
  },
  { providerName: 'AOL', provider: 'aol', url: 'https://mail.aol.com/webmail/' },
  {
    providerName: 'Outlook',
    provider: 'outlook',
    url: 'https://outlook.live.com/owa/a/',
  },
  { providerName: 'ICloud', provider: 'icloud', url: 'https://www.icloud.com/mail/' },
];

const useCaseCheckYourEmail = (currentStep: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );
  const bookingFormData = useAppSelector(selectBookingForm);

  const [counter, setCounter] = useState(0);
  const intervalId = useRef<ReturnType<typeof setInterval>>();

  const adapter = useInjection<ICheckYourEmailAdapter>(CheckYourEmailAdapter);
  const {
    error,
    result,
    inProgress,
    navigateToEmailProviderMailbox,
    resendVerificationLink,
    navigateToGenericMailbox,
    isLoggedIn,
  } = adapter();

  const extractEmailProviderFromEmail = (providedEmail: string) => {
    const emailProvider = providedEmail.substring(
      providedEmail.indexOf('@') + 1,
      providedEmail.lastIndexOf('.'),
    );
    return commonEmailProviders.find((item) => item.provider === emailProvider);
  };

  const handleOpenMailbox = (providedEmail: string) => {
    const emailProvider = extractEmailProviderFromEmail(providedEmail);
    if (emailProvider?.url) {
      navigateToEmailProviderMailbox(emailProvider?.url, providedEmail);
    } else {
      navigateToGenericMailbox();
    }
  };

  const handleResendEmail = async (email: string, locationId: string) => {
    setCounter(30);
    startCounter();
    await resendVerificationLink(email, locationId, bookingFormData.bookingUUID);
  };

  useEffect(
    () => () => {
      clearInterval(intervalId.current);
    },
    [],
  );

  useEffect(() => {
    if (counter > 0) {
      intervalId.current = setInterval(() => setCounter(counter - 1), 1000);
    }
    return () => clearInterval(intervalId.current);
  }, [counter]);

  const clearCounterInterval = () => {
    if (intervalId) {
      clearInterval(intervalId.current);
      intervalId.current = undefined;
      setCounter(30);
    }
  };

  const startCounter = () => {
    clearCounterInterval();
    const counterInterval = setInterval(() => {
      if (counter > 1) {
        setCounter(counter - 1);
      } else {
        clearInterval(counterInterval);
      }
    }, 1000);
    intervalId.current = counterInterval;
  };

  const handleGetEmailProvider = (providedEmail: string) => {
    const emailProvider = extractEmailProviderFromEmail(providedEmail);
    if (!emailProvider) {
      return null;
    }
    return emailProvider;
  };

  return {
    inProgress,
    error,
    result,
    counter: counter,
    getEmailProvider: handleGetEmailProvider,
    openMailbox: handleOpenMailbox,
    resendEmail: handleResendEmail,
    prevStep: () => {
      if (isLoggedIn) {
        bookingWizardNavigation.redirectToStep(WizardStep.checkoutSignIn);
      } else {
        bookingWizardNavigation.redirectToPrevStep(currentStep);
      }
    },
  };
};

export default useCaseCheckYourEmail;
