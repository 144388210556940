import { AuthDto } from 'common';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';
import { BookingForm } from 'infrastructure/redux/slices/bookingWizard.slice';

export enum CreateAccountError {
  InvalidEmail = 'invalid_email',
  Unknown = 'unknown',
  ClientAlreadyExists = 'client_exists',
  Error = 'error_creating_client',
}

export const CreateAccountAdapter = Symbol('CreateAccountAdapter');
export type ICreateAccountAdapter = InjectableHook<
  AsyncHookResult<unknown, CreateAccountError> & {
    sendVerificationLink: (values: AuthDto.RegisterDto, bookingUUID?: string) => void;
    handleNavigateToCheckYourEmail: () => void;
    handleNavigateToSignIn: () => void;
    isSuccess: boolean;
    bookingFormData: BookingForm;
    handleSaveBookingData: () => Promise<string>;
  }
>;

const useCaseCreateAccount = () => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );

  const adapter = useInjection<ICreateAccountAdapter>(CreateAccountAdapter);
  const {
    error,
    result,
    isSuccess,
    inProgress,
    sendVerificationLink,
    handleNavigateToCheckYourEmail,
    handleNavigateToSignIn,
    bookingFormData,
    handleSaveBookingData,
  } = adapter();
  const [isLoading, setIsLoading] = useState(false);

  const handleNextStep = async (values: AuthDto.RegisterDto) => {
    setIsLoading(true);
    try {
      const bookingUUID = await handleSaveBookingData();
      if (bookingUUID) {
        sendVerificationLink(values, bookingUUID);
      } else {
        sendVerificationLink(values);
      }
    } catch (e) {
      sendVerificationLink(values);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      bookingWizardNavigation.redirectToStep(WizardStep.checkoutCheckYourEmail);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!bookingFormData?.location) {
      bookingWizardNavigation.redirectToStep(WizardStep.selectLocation);
    }
    if (bookingFormData?.location && !bookingFormData?.experienceType) {
      bookingWizardNavigation.redirectToStep(WizardStep.selectExperience);
    }
    if (
      (bookingFormData?.experienceType === BookingWizardFlowTypes.Simulator ||
        bookingFormData?.experienceType === BookingWizardFlowTypes.Bowling) &&
      !bookingFormData?.reservationDate
    ) {
      bookingWizardNavigation.redirectToStep(WizardStep.reservationDate);
    }
    if (
      bookingFormData?.experienceType === BookingWizardFlowTypes.Lesson &&
      !bookingFormData?.instructor
    ) {
      bookingWizardNavigation.redirectToStep(WizardStep.selectInstructor);
    }
    if (
      (bookingFormData?.experienceType === BookingWizardFlowTypes.Simulator ||
        bookingFormData?.experienceType === BookingWizardFlowTypes.Bowling) &&
      !bookingFormData?.appointments
    ) {
      bookingWizardNavigation.redirectToStep(WizardStep.sessionLength);
    }
  }, [bookingFormData]);

  return {
    inProgress: inProgress || isLoading,
    error,
    result,
    handleNavigateToSignIn,
    handleNavigateToCheckYourEmail,
    nextStep: handleNextStep,
    prevStep: () => {
      bookingWizardNavigation.redirectToStep(WizardStep.checkoutSignIn);
    },
  };
};

export default useCaseCreateAccount;
