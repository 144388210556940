import { IGetSimulatorLocationsHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import { setUserSummary } from 'infrastructure/redux/slices/user.slice';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';

const useGetSimulatorLocations: IGetSimulatorLocationsHook = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);
  const [getUser] = mboAPI.useLazyGetUserDataQuery();

  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch: fetchLocations,
  } = mboAPI.useGetSimulatorLocationsQuery();

  const handleGetUserData = async (newLocationId: string) => {
    if (accessToken) {
      const payload = await getUser(newLocationId).unwrap();
      dispatch(setUserSummary(payload));
    }
  };

  return {
    inProgress: isLoading || isFetching,
    error,
    result: data,
    fetchLocations,
    handleGetUserData,
  };
};

export default useGetSimulatorLocations;
