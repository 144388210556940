import { AppointmentTypes } from 'common';
import {
  FirstTimeLessonAppointmentAvailability,
  LessonAppointmentAvailability,
} from 'common/dist/infrastructure/modules/appointment/interfaces/AvailabilityTypes';
import { UserSummary } from 'common/dist/infrastructure/modules/auth/interfaces/UserTypes';
import { useInjection } from 'inversify-react';

import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';
import { IButtonGroupButton } from 'infrastructure/components/ButtonsGroup';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';

export enum ChooseATimeError {
  Unknown = 'unknown',
}

export enum AppointmentPricingError {
  Unknown = 'unknown',
}

export enum AppointmentError {
  Unknown = 'unknown',
}

export const ChooseATimeAdapter = Symbol('ChooseATimeAdapter');

export type IChooseATimeAdapter = InjectableHook<
  AsyncHookResult<Array<FirstTimeLessonAppointmentAvailability>, ChooseATimeError> & {
    selectedInstructor: Partial<LessonAppointmentAvailability> | undefined;
    isFirstTimeLesson: boolean;
    availableFirstTimeLessonSlots: Array<IButtonGroupButton> | undefined;
    lessonTypes: Array<IButtonGroupButton> | undefined;
    getPricing: (values: ChooseATimeForm) => void;
    getAvailableLessonSlotButtons: (
      selectedLessonLength: number,
    ) => Array<IButtonGroupButton> | undefined;
    saveAppointmentToStore: (values: ChooseATimeForm) => void;
    areAppointmentsLoading: boolean;
    appointmentError: AppointmentError | undefined;
    pricingError: AppointmentPricingError | undefined;
    appointentPricing: AppointmentTypes.ServicePricing[] | undefined;
    isPricingLoading: boolean;
    userData: UserSummary;
  }
>;

export interface ChooseATimeForm {
  location: string;
  reservationDate: string | Date | undefined;
  sessionHour: string;
  lessonLength: number | undefined;
  sessionTypeId: number | undefined;
  resourceId: number | undefined;
  staffId: number | undefined;
}

const useCaseChooseATimeForLesson = (currentStep: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );

  const adapter = useInjection<IChooseATimeAdapter>(ChooseATimeAdapter);
  const {
    error,
    result,
    inProgress,
    selectedInstructor,
    lessonTypes,
    isFirstTimeLesson,
    availableFirstTimeLessonSlots,
    getAvailableLessonSlotButtons,
    saveAppointmentToStore,
    pricingError,
    appointentPricing,
    isPricingLoading,
    getPricing,
    areAppointmentsLoading,
    appointmentError,
    userData,
  } = adapter();

  const handleNextStep = (values: ChooseATimeForm) => {
    saveAppointmentToStore(values);
    if (!userData.creditCard) {
      bookingWizardNavigation.redirectToStep(WizardStep.paymentDetails);
    } else {
      bookingWizardNavigation.redirectToNextStep(currentStep);
    }
  };

  return {
    error,
    result,
    inProgress,
    selectedInstructor,
    availableFirstTimeLessonSlots,
    getAvailableLessonSlotButtons,
    areAppointmentsLoading,
    appointmentError,
    lessonTypes,
    pricingError,
    appointentPricing,
    isPricingLoading,
    getPricing,
    nextStep: handleNextStep,
    prevStep: () => {
      bookingWizardNavigation.redirectToPrevStep(currentStep);
    },
    isFirstTimeLesson,
  };
};

export default useCaseChooseATimeForLesson;
