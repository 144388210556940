import { AppointmentTypes } from 'common';
import { isValid, parse } from 'date-fns';

export const formatPricing = (pricings: AppointmentTypes.ServicePricing[] = []) => {
  const appointments: AppointmentTypes.Appointment[] = [];
  for (const pricing of pricings) {
    for (const costSummary of pricing.costSummary) {
      const appointment: AppointmentTypes.Appointment = {
        title: costSummary.title,
        price: costSummary.price,
        duration: costSummary.duration,
        sessionTypeId: costSummary.sessionTypeId,
        staffId: pricing.staffId,
        startDateTime: costSummary.startTime,
        endDateTime: costSummary.endTime,
        resourceId: undefined,
      };
      appointments.push(appointment);
    }
  }

  const addOn = pricings.length > 0 ? pricings[0].addOns : undefined;
  return {
    addOn: addOn,
    appointments: appointments,
  };
};

export const isValidDateString = (date: string) => {
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth() + 1;
  const year = new Date(date).getFullYear();
  const parsed = parse(`${month}/${day}/${year}`, 'P', new Date());
  return isValid(parsed);
};
